import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';
import { ModelsModule } from '../models/models.module';
import { WorkspaceService } from './workspace.service';
import { SidebarService } from './sidebar.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    IonicModule,
    ModelsModule,
    TranslateModule.forChild(),
  ]
})
export class WorkspaceModule {
  static forChild(): ModuleWithProviders<WorkspaceModule> {
    return { ngModule: WorkspaceModule };
  }

  static forRoot(
    service?: WorkspaceService
  ): ModuleWithProviders<WorkspaceModule> {
    return {
      ngModule: WorkspaceModule,
      providers: [
        WorkspaceService,
        SidebarService
      ]
    };
  }
}
