import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ModelProvider } from 'src/app/core/models/general/model.provider';
import { first } from 'rxjs/operators';
import { Organization } from 'src/app/core/models/organization.model';
import { Plan } from 'src/app/core/models/plan';
import { DocumentReference } from '@angular/fire/firestore';

@Component({
  selector: 'app-superadmin-organization-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
})
export class SuperadminOrganizationEditComponent implements OnInit {
  public processing = false;
  public organization: Organization;
  public plans: Plan[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private modelProvider: ModelProvider
  ) {}

  async ngOnInit() {
    const organizationID = this.route.snapshot.paramMap.get('id');

    if (organizationID) {
      this.organization = await this.modelProvider.organization.findByID(organizationID).pipe(first()).toPromise();
      if (!this.organization) {
        this.handleMissingOrganization();
        return;
      }
      if (!this.organization?.stripe) {
        this.organization.stripe = { customerID: '', priceID: '', subscriptionID: '' };
      }
      this.plans = await this.modelProvider.plan.findAllBy(ref => ref).pipe(first()).toPromise();
    }
  }

  private async handleMissingOrganization() {
    await this.router.navigate(['superadmin', 'organization']);
  }

  async save() {
    this.processing = true;
    await this.organization.save();
    this.processing = false;
    await this.router.navigate(['superadmin', 'organization']);
  }

  byReference(item1: DocumentReference, item2: DocumentReference) {
    if (!item1 || !item2) {
      return false;
    }
    return item1.isEqual(item2);
  }
}
