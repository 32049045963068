<div class="nav-footer">
  <div
    *ngIf="isSuperadmin"
    [routerLink]="['/superadmin', 'dashboard']"
    [ngClass]="{
      'nav-footer-item': true,
      active: active === 'superadmin',
      'side-bar-open': isSideBarOpen
    }"
  >
    <mat-icon slot="icon-only">admin_panel_settings</mat-icon>
    <span *ngIf="isSideBarOpen">Superadmin</span>
  </div>
  <div
    *ngIf="!isPlayLandingPageVisible()"
    [routerLink]="['/play-landing']"
    (click)="analytics.onPlayButtonClicked(true)"
    [ngClass]="{
      'nav-footer-item': true,
      active: active === 'player',
      'side-bar-open': isSideBarOpen
    }"
  >
    <mat-icon slot="icon-only">install_mobile</mat-icon>
    <span *ngIf="isSideBarOpen">Player</span>
  </div>
</div>
