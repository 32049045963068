<app-new-team-sidebar active="superadmin"></app-new-team-sidebar>
<ion-content>
  <app-profile-menu></app-profile-menu>
  <content-container class="py-5 px-3" [back-button]="'/superadmin/plan'" [pageTitle]="(plan?.isNew) ? 'Create Plan' : 'Edit Plan' ">
    <context-menu-icon>
      <context-item [disabled]="plan?.pricing?.yearly" [label]="'Add yearly price'">
        <context-group>
          <context-item *ngFor="let currency of ['gbp', 'usd']" [label]="currency | uppercase"
            (click)="plan.addPrice('yearly', currency)"></context-item>
        </context-group>
      </context-item>

      <context-item [disabled]="plan?.pricing?.monthly" [label]="'Add monthly price'">
        <context-group>
          <context-item *ngFor="let currency of ['gbp', 'usd']" [label]="currency | uppercase"
            (click)="plan.addPrice('monthly', currency)"></context-item>
        </context-group>
      </context-item>


      <context-item class="divider highlighted" label="Copy link for customer" (click)="copyLink()">
      </context-item>
    </context-menu-icon>

    <form *ngIf="plan" #form="ngForm" class="form">
      <div class="form-group col-sm-6 px-0">
        <label for="title">Plan name</label>
        <input [(ngModel)]="plan.title" name="title" type="text" required />
      </div>

      <div class="form-group col-sm-6 px-0">
        <label for="stripeProductID">Stripe Product ID</label>
        <input [(ngModel)]="plan.stripeID" name="stripeProductID" type="text" />
      </div>

      <h3 class="mt-5">Features</h3>
      <div class="row">
        <div class="col-sm-3">
          <div class="form-group">
            <label for="feature-workspaceLimit">Workspace limit</label>
            <input [(ngModel)]="plan.features.workspaceLimit" name="feature-workspaceLimit" id="feature-workspaceLimit"
              type="number" />
          </div>
        </div>
        <div class="col-sm-3">
          <div class="form-group">
            <label for="feature-scriptPerWorkspaceLimit">Game / Workspace limit</label>
            <input [(ngModel)]="plan.features.scriptPerWorkspaceLimit" name="feature-scriptPerWorkspaceLimit"
              id="feature-scriptPerWorkspaceLimit" type="number" />
          </div>
        </div>
        <div class="col-sm-3">
          <div class="form-group">
            <label for="feature-designerLimit">Designer / Workspace limit</label>
            <input [(ngModel)]="plan.features.designerLimitPerWorkspace" name="feature-designerLimit"
              id="feature-designerLimit" type="number" />
          </div>
        </div>
        <div class="col-sm-3">
          <div class="form-group">
            <label for="feature-playerLimit">Player limit</label>
            <input [(ngModel)]="plan.features.playerLimit" name="feature-playerLimit" id="feature-playerLimit"
              type="number" />
          </div>
        </div>
      </div>

      <div *ngIf="plan?.pricing" class="row mt-3">
        <div *ngIf="plan?.pricing?.monthly" class="col-sm-6">
          <h3>Monthly</h3>
          <ng-container *ngFor="let currencyItem of plan.pricing.monthly.currencies | keyvalue">
            <ng-container *ngIf="currencyItem.key; let currency">
              <ng-container *ngIf="currencyItem.value; let currencyValue">
                <div class="form-group">
                  <label for="monthly-currency-amount">{{currency | uppercase}} Amount</label>
                  <input [(ngModel)]="plan.pricing.monthly.currencies[currency].amount" name="monthly-currency-amount"
                    type="number" id="monthly-currency-amount" />
                </div>
                <div class="form-group">
                  <label for="monthly-stripeID">Stripe Price ID</label>
                  <input [(ngModel)]="plan.pricing.monthly.currencies[currency].stripeID" name="monthly-stripeID"
                    type="text" id="monthly-stripeID" class="col-sm-8 px-0" />
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
          <div class="form-group">
            <label for="montly-invoiceDaysUntilDue">Invoice Days until due</label>
            <input [(ngModel)]="plan.pricing.monthly.invoiceDaysUntilDue" name="montly-invoiceDaysUntilDue"
              id="montly-invoiceDaysUntilDue" type="number" min="0" />
          </div>
          <div class="form-group">
            <label for="montly-trialPeriod">Trial period (days)</label>
            <input [(ngModel)]="plan.pricing.monthly.trialPeriodDays" name="montly-trialPeriod" id="montly-trialPeriod"
              type="number" min="0" />
          </div>
        </div>
        <div *ngIf="plan?.pricing?.yearly" class="col-sm-6">
          <h3>Yearly</h3>
          <ng-container *ngFor="let currencyItem of plan.pricing.yearly.currencies | keyvalue">
            <ng-container *ngIf="currencyItem.key; let currency">
              <ng-container *ngIf="currencyItem.value; let currencyValue">
                <div class="form-group">
                  <label for="yearly-currency-amount">{{currency | uppercase}} Amount</label>
                  <input [(ngModel)]="plan.pricing.yearly.currencies[currency].amount" name="yearly-currency-amount"
                    type="number" id="yearly-currency-amount" />
                </div>
                <div class="form-group">
                  <label for="yearly-stripeID">Stripe Price ID</label>
                  <input [(ngModel)]="plan.pricing.yearly.currencies[currency].stripeID" name="yearly-stripeID"
                    class="col-sm-8 px-0" type="text" id="yearly-stripeID" />
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
          <div class="form-group">
            <label for="invoiceDaysUntilDue">Invoice Days until due</label>
            <input [(ngModel)]="plan.pricing.yearly.invoiceDaysUntilDue" name="yearly-invoiceDaysUntilDue" type="number"
              min="0" />
          </div>
          <div class="form-group">
            <label for="trialPeriod">Trial period (days)</label>
            <input [(ngModel)]="plan.pricing.yearly.trialPeriodDays" name="yearly-trialPeriod" type="number" min="0" />
          </div>
        </div>
      </div>

      <div class="from-group mt-3">
        <ion-button (click)="save()" [disabled]="form.form.invalid || processing" shape="round">
          <ion-spinner *ngIf="processing" name="lines-small"></ion-spinner>
          Save Plan
        </ion-button>
      </div>
    </form>
  </content-container>
</ion-content>