import { cloneDeep } from 'lodash-es';

export abstract class Model {
  static RULE_ALIAS = 'alias';
  static RULE_DEFAULT = 'default';
  static RULE_CLASS = 'class';
  static RULE_MAP_TO_CLASS = 'mapToClass';

  constructor(data, protected options?: { skipSetFields?: boolean, withModelData?: boolean }) {
    if (options && options.skipSetFields === true) {

    } else {
      if (options && options.withModelData) {
        this.setModelFields(data);
      } else {
        this.setFields(data);
      }
    }
  }

  public toData(properties?: Array<string>): any {
    const data = {};
    for (const propertyName in this.getRules()) {
      if (this.getRules().hasOwnProperty(propertyName) && (!properties || properties && properties.indexOf(propertyName) >= 0)) {
        if (this.hasOwnProperty(propertyName)) {
          if (this[propertyName] && this[propertyName].toData) {
            data[this.getFieldNameByPropertyName(propertyName)] = this[propertyName].toData();
          } else {
            data[this.getFieldNameByPropertyName(propertyName)] = this[propertyName];
          }
        }
      }
    }

    return data;
  }

  protected getRules() {
    if (this['rules']) {
      return (this as any).rules();
    } else {
      throw new Error('No rules found in ' + this.constructor.name);
    }
  }

  public refresh(properties?: string[]) {
    if (!properties) {
      const properties = new Array<string>();
      const rules = this.getRules();
      for (const propertyName in rules) {
        properties.push(propertyName);
      }
    }

    const fieldData = this.toData(properties);

    const data = {};
    properties.forEach(propertyName => {
      const fieldName = this.getFieldNameByPropertyName(propertyName);
      data[fieldName] = cloneDeep(fieldData[fieldName]);
    });

    this.setFields(data);
  }

  public setFields(data: any) {
    const rules = this.getRules();

    for (const fieldName in data) {
      if (data.hasOwnProperty(fieldName) && rules[this.getPropertyNameByFieldName(fieldName)]) {
        // Convert to specific class
        if (rules[this.getPropertyNameByFieldName(fieldName)].hasOwnProperty(Model.RULE_CLASS)) {
          const fieldClass = rules[this.getPropertyNameByFieldName(fieldName)][Model.RULE_CLASS];

          if (fieldClass) {
            this[this.getPropertyNameByFieldName(fieldName)] = this.instantiateClass(fieldClass, data[fieldName], rules[this.getPropertyNameByFieldName(fieldName)]);
          } else {
            this[this.getPropertyNameByFieldName(fieldName)] = data[fieldName];
          }
        } else {
          this[this.getPropertyNameByFieldName(fieldName)] = data[fieldName];
        }
      }
    }
  }

  /**
   * For creation
   * @param data 
   */
  public setModelFields(data: any) {
    const rules = this.getRules();

    for (const propertyName in data) {
      if (data.hasOwnProperty(propertyName) && rules[this.getPropertyNameByFieldName(propertyName)]) {
        if (rules[this.getPropertyNameByFieldName(propertyName)].hasOwnProperty(Model.RULE_CLASS)) {
          const fieldClass = rules[this.getPropertyNameByFieldName(propertyName)][Model.RULE_CLASS];
          this[this.getPropertyNameByFieldName(propertyName)] = this.instantiateClass(fieldClass, {}, { skipSetFields: true });
          this[propertyName] = data[propertyName];
        } else {
          this[propertyName] = data[propertyName];
        }
      }
    }
  }

  public setDefaultValues() {
    const rules = this.getRules();
    for (const propertyName in rules) {
      if (rules[propertyName] && rules[propertyName].hasOwnProperty(Model.RULE_DEFAULT)) {
        if (
          [null, undefined].indexOf(this[propertyName]) >= 0
          // || this[propertyName].constructor === Object && Object.keys(this[propertyName]).length === 0
        ) {
          if(typeof rules[propertyName][Model.RULE_DEFAULT] === 'function') {
            this[propertyName] = rules[propertyName][Model.RULE_DEFAULT]();
          } else {
            this[propertyName] = rules[propertyName][Model.RULE_DEFAULT];
          }

          if (this[propertyName]['setDefaultValues']) {
            this[propertyName].setDefaultValues();
          }
        }
      }
    }
  }

  protected instantiateClass(fieldClass: Model, data: any, options: any = {}) {
    return (fieldClass as any).instantiate(data, this, options);
  }

  protected getFieldNameByPropertyName(propertyName: string) {
    const rules = this.getRules();
    return (rules[propertyName].hasOwnProperty(Model.RULE_ALIAS)) ?
      rules[propertyName][Model.RULE_ALIAS] :
      propertyName;
  }

  protected getPropertyNameByFieldName(fieldName: string) {
    const rules = this.getRules();
    for (const propertyName in rules) {
      if (rules.hasOwnProperty(propertyName)) {
        const rule = rules[propertyName];

        if (rule.hasOwnProperty(Model.RULE_ALIAS) && rule[Model.RULE_ALIAS] === fieldName) {
          return propertyName;
        }
      }
    }

    return fieldName;
  }
}
