<app-new-team-sidebar active="superadmin"></app-new-team-sidebar>
<ion-content>
  <app-profile-menu></app-profile-menu>
  <content-container class="py-5 px-3" [back-button]="'/superadmin/organization'"
    [pageTitle]="(organization?.isNew) ? 'Create Team' : 'Edit Team' ">
    <form *ngIf="organization" #form="ngForm" class="form">
      <div class="form-group col-sm-6 px-0">
        <label for="title">Team name</label>
        <input [(ngModel)]="organization.title" name="title" type="text" required />
      </div>

      <div class="form-group col-sm-6 px-0">
        <label for="status">Plan</label>
        <select [(ngModel)]="organization.plan.ref" [compareWith]="byReference" name="planRef" required class="form-control w-auto pr-5">
          <ng-container *ngFor="let plan of plans">
            <option [ngValue]="plan.ref">{{plan.title}}</option>
          </ng-container>
        </select>
      </div>

      <div class="form-group col-sm-6 px-0">
        <label for="status">Status</label>
        <select [(ngModel)]="organization.plan.status" name="planStatus" required class="form-control w-auto pr-5">
          <ng-container *ngFor="let status of ['active', 'past_due', 'canceled', 'unpaid']">
            <option [value]="status">{{'organization/plan/status/' + status | translate}}</option>
          </ng-container>
        </select>
      </div>

      <div class="form-group col-sm-6 px-0">
        <label for="title">Stripe Customer ID</label>
        <input [(ngModel)]="organization.stripe.customerID" name="stripeCustomerID" type="text" />
      </div>

      <div class="form-group col-sm-6 px-0">
        <label for="title">Stripe Price ID</label>
        <input [(ngModel)]="organization.stripe.priceID" name="stripePriceID" type="text" />
      </div>

      <div class="form-group col-sm-6 px-0">
        <label for="title">Stripe Subscription ID</label>
        <input [(ngModel)]="organization.stripe.subscriptionID" name="stripeSubscriptionID" type="text" />
      </div>

      <div class="from-group mt-5">
        <ion-button (click)="save()" [disabled]="form.form.invalid || processing" shape="round">
          <ion-spinner *ngIf="processing" name="lines-small"></ion-spinner>
          Save Team
        </ion-button>
      </div>

    </form>
  </content-container>
</ion-content>