import { Component, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { ToastController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/auth/auth.service';
import { filter, first } from 'rxjs/operators';
import { User } from 'src/app/core/models/user.model';
import { ModelProvider } from 'src/app/core/models/general/model.provider';

@Component({
  selector: 'app-login-as',
  templateUrl: './login-as.page.html',
  styleUrls: ['./login-as.page.scss'],
})
export class LoginAsPage implements OnInit {

  public userID = '';

  environment = environment;
  projectId = environment.firebaseConfig.projectId;
  processing: string;

  public users: User[];
  public usersLimit = 10;

  constructor(
    private fns: AngularFireFunctions,
    public toastController: ToastController,
    public modelProvider: ModelProvider,
    private auth: AuthService,
  ) { }

  async ngOnInit() {

  }

  async ionViewWillEnter() {
    await this.auth.authState().pipe(first()).toPromise();
    const isSuperAdmin = await this.auth.isSuperAdmin();
    if(!isSuperAdmin) {
      await this.auth.navigateToHome();
      return;
    }
    this.users = await this.modelProvider.user.findAllBy(ref => ref.orderBy('lastLoginAt', 'desc').limit(this.usersLimit)).pipe(first()).toPromise();
    // this.users = await this.modelProvider.user.findAllBy(ref => ref.orderBy('lastLoginAt', 'desc').limit(this.usersLimit)).pipe(first()).toPromise();
  }

  async presentToast(message) {
    const toast = await this.toastController.create({
      message: message,
      duration: 2000
    });
    toast.present();
  }

  async submit(_userID?: string) {
    try {
      this.processing = _userID;
      const userID = _userID || this.userID
      const callable = this.fns.httpsCallable('authLoginAs');
      const data = await callable({ userID: userID }).pipe(first()).toPromise();
      if (data && data.token) {
        await this.auth.signOut();
        await this.auth.signInWithCustomToken(data.token);
        this.presentToast('Wait until browser refreshes...')
        this.userID = '';
        await this.auth.authState().pipe(first(), filter( user => !!user)).toPromise();
        await this.auth.setHomeURL(await this.auth.getHomeURL());
        await this.auth.navigateToHome();
        window.location.reload();
      }
    } catch (error: any) {
      console.log((error as Error).message, (error as Error).message.search("not found by ref") >= 0);
      if((error as Error).message.search("not found by ref") >= 0) {
        this.userID = '';
        // await this.auth.navigateToHome();
        await this.auth.setHomeURL(await this.auth.getHomeURL());
        window.location.reload();
      } else {
        console.log(error);        
      }
    } finally {
      this.processing = null;
    }

  }

}
