import { LayoutItem } from './task-layout-item';
import { Task } from './task.model';
import firebase from "firebase/app";

export class ImageLayoutItem extends LayoutItem {
    public type: string = LayoutItem.TYPE_IMAGE;
    public order = 10;

    public properties: {
        transcoding?: {
            isActive: boolean,
            startedAt: firebase.firestore.Timestamp,
            finishedAt?: firebase.firestore.Timestamp
        },
        sizes?: {
            original: {
                ref?: string;
                url: string;
                mimeType: string,
                size: string
            },
            md?: {
                ref: string;
                url: string;
                mimeType: string,
                size: string,
            },
            sm?: {
                ref: string;
                url: string;
                mimeType: string,
                size: string,
            }
            xs?: {
                ref: string;
                url: string;
                mimeType: string,
                size: string,
            }
        },
    };

    public setFields(data: any) {
        this.properties = {
            sizes: {
                original: {
                    ref: null,
                    url: null,
                    mimeType: null,
                    size: null
                }
            }
        };
        super.setFields(data);
    }

    public get url() {
        const sizes = this.modelProvider.platform.is('desktop') ? ['md','800x', '576x', 'original'] : ['sm','576x', '800x', 'original'];
        for (const size of sizes) {
            if (this.properties && this.properties.sizes && this.properties.sizes[size] && this.properties.sizes[size].url) {
                return this.properties.sizes[size].url;
            }
        }

        return undefined;
    }

    public setImage(url: string, ref: string = null, mimeType: string, size = 'original') {
        this.properties = {
            sizes: {
                original: {
                    url: url,
                    ref: ref,
                    mimeType: mimeType,
                    size: size
                }
            }
        }
    }

    public uploadFile(category: string, file: File, metadata?: any) {
        // console.log('upload image', category, file, metadata)
        const path = (this.documentInstance as Task).getDocument().ref.path + '/' + this.type + '/' + file.name;
        const reference = this.modelProvider.fsStorage.ref(path);
        return { ref: reference, uploadTask: reference.put(file, { customMetadata: metadata || {} }) };
    }

    private isLocalFile() {
        return (this.properties && this.properties.sizes && this.properties.sizes.original && this.properties.sizes.original.url.startsWith('blob:'));
    }

    public isTranscoded() {
        return !!!(this.properties && this.properties.sizes && this.properties.sizes.md && this.properties.sizes.md.url);
    }

    public transcoded$() {
        return !this.isLocalFile();
    }

    public get task() {
        return this.documentInstance as Task
    }
}
