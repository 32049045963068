import { Injectable } from '@angular/core';
import { ScriptVersion } from 'src/app/core/models/script-version.model';
import { Play } from 'src/app/core/models/play.model';
import { Player } from 'src/app/core/models/player.model';
import { Role } from 'src/app/core/models/role.model';
import { Team } from 'src/app/core/models/team.model';
import { Task } from 'src/app/core/models/task.model';
import { first, map, switchMap } from 'rxjs/operators';
import { ModelProvider } from 'src/app/core/models/general/model.provider';
import { Observable, Subscription, ReplaySubject, combineLatest } from 'rxjs';
import { AlertController } from '@ionic/angular';
import { isEqual } from 'lodash-es';
import { Script } from 'src/app/core/models/script.model';
import { User } from 'src/app/core/models/user.model';
import { Workspace } from 'src/app/core/models/workspace.model';
import { Organization } from 'src/app/core/models/organization.model';

@Injectable({
  providedIn: 'root'
})
export class PlayService {
  private subscriptions: Subscription[] = [];

  public afterInit$ = new ReplaySubject<boolean>();

  public script: Script;
  public script$: Observable<Script>;
  public scriptVersion: ScriptVersion;
  public scriptVersion$: Observable<ScriptVersion>;
  public play: Play;
  public play$: Observable<Play>;
  public myPlayer: Player;
  public myPlayer$: ReplaySubject<Player>;
  public myRole: Role;
  public myRole$: ReplaySubject<Role>;
  public roles$: Observable<Role[]>;
  public roles: Role[];
  public taskLevels: Map<Task, number>;
  public myTeam: Team;
  public myTeam$: ReplaySubject<Team>;
  public players: Array<Player> = [];
  public players$: Observable<Array<Player>>;
  public myTeamPlayers: Array<Player> = [];
  public myTeamPlayers$: ReplaySubject<Array<Player>>;
  public teams: Array<Team>;
  public teams$: Observable<Array<Team>>;
  public workspace: Workspace;
  public workspace$: Observable<Workspace>;
  public organization: Organization;
  public organization$: Observable<Organization>;

  public tasks: Array<Task>;
  public tasks$: Observable<Array<Task>>;

  public myTasksDone: Array<Task>;
  public myTasksDone$: Observable<Array<Task>>;
  public myTasksActual$: Observable<Array<Task>>;
  public myTasksActual: Array<Task>;

  meUser: User;

  constructor(
    public modelProvider: ModelProvider,
    public alertController: AlertController
  ) { }

  async init(playID) {
    this.meUser = await this.modelProvider.user.getMe$().pipe(first()).toPromise();

    this.play = null;
    this.myPlayer$ = new ReplaySubject<Player>(1);
    this.myRole$ = new ReplaySubject<Role>(1);
    this.myTeam$ = new ReplaySubject<Team>(1);
    this.myTeamPlayers$ = new ReplaySubject<Array<Player>>(1);
    this.myTasksActual$ = new ReplaySubject<Array<Task>>(1);
    this.myTasksDone$ = new ReplaySubject<Array<Task>>(1);
    this.tasks$ = new ReplaySubject<Array<Task>>(1);
    this.roles$ = new ReplaySubject<Array<Role>>(1);
    this.taskLevels = new Map<Task, number>();

    this.play$ = this.modelProvider.play.findByRef('plays/' + playID);
    const play$ = this.play$.subscribe(play => {
      this.play = play;
    });
    this.subscriptions.push(play$);
    await this.play$.pipe(first()).toPromise();

    this.script$ = this.play$.pipe(switchMap(play => play.script$)).pipe(first());
    const script$ = this.script$.subscribe((script) => {
      this.script = script;
    });
    this.subscriptions.push(script$);

    this.workspace$ = this.play.workspace$;
    this.workspace$.pipe(first()).subscribe(async workspace => {
      this.workspace = workspace;
      if (workspace.ownerOrganizationRef) {
        this.organization$ = workspace.ownerOrganization$;
        this.organization = await this.organization$.pipe(first()).toPromise();
      }
    })

    this.scriptVersion$ = this.play$.pipe(
      switchMap(play => play.scriptVersion$));
    const scriptVersion$ = this.scriptVersion$.subscribe((scriptVersion) => {
      this.scriptVersion = scriptVersion;
    });
    this.subscriptions.push(scriptVersion$);
    await this.scriptVersion$.pipe(first()).toPromise();

    this.players$ = this.play.players$;

    // MY PLAYER
    const myPlayer$ = this.players$.subscribe(players => {
      this.players = players;
      const myPlayer = players
        .filter(player => player.userRef)
        .find(player => player.userRef.isEqual(this.modelProvider.user.meReference));

      if (myPlayer) {
        if (!this.myPlayer) {
          this.myPlayer = myPlayer;
          this.myPlayer$.next(this.myPlayer);
        } else {
          if (!isEqual(this.myPlayer.toData(), myPlayer.toData())) {
            this.myPlayer = myPlayer;
            this.myPlayer$.next(this.myPlayer);
          }

        }
      } else {
        // Deleted
        if (this.myPlayer) {
          this.myPlayer = myPlayer;
          this.myPlayer$.next(this.myPlayer);
        } else {
          this.myPlayer = undefined;
          this.myPlayer$.next(undefined);
        }
      }
    });
    this.subscriptions.push(myPlayer$);

    this.tasks$ = this.scriptVersion$.pipe(switchMap(version => version.tasks$));
    const tasks$ = this.tasks$.subscribe(tasks => {
      this.tasks = tasks;
      
      this.taskLevels = new Map<Task, number>();
      for (const task of tasks) {
        this.taskLevels.set(task, task.getDependencyLevel(tasks));
      }
    });
    this.subscriptions.push(tasks$);

    this.roles$ = this.scriptVersion$.pipe(
      switchMap(version => version.roles$),
      map(roles => roles.sort((a: Role, b: Role) => {
        if (a.type === 'facilitator') {
          return 1;
        }
        if (b.type === 'facilitator') {
          return -1;
        }

        if (a.order && b.order && a.order !== b.order) {
          return (a.order > b.order) ? -1 : 1;
        } else {
          return ('' + a.title).localeCompare(b.title);
        }
      })),
    )
    const roles$ = this.roles$.subscribe(roles => {
      this.roles = roles;
    });
    this.subscriptions.push(roles$);
    // });

    // TEAMS
    this.teams$ = this.play.teams$;
    const teams$ = this.teams$.subscribe(teams => {
      this.teams = teams;
    });
    this.subscriptions.push(teams$);

    // MY TEAM
    const myTeam$ = combineLatest([this.teams$, this.myPlayer$]).subscribe(([teams, myPlayer]) => {
      if (!myPlayer) {
        this.myTeam = undefined;
        this.myTeam$.next(undefined);
        return;
      }
      this.myTeam = teams.find(team =>
        myPlayer.teamRef.isEqual(team.getReference())
      );
      this.myTeam$.next(this.myTeam);
    });
    this.subscriptions.push(myTeam$);

    // MY TEAM PLAYERS
    const myTeamPlayers$ = combineLatest([this.players$, this.myTeam$]).subscribe(([players, myTeam]) => {
      if (!players || !myTeam) {
        this.myTeamPlayers = undefined;
        this.myTeamPlayers$.next(undefined);
        return;
      }

      this.myTeamPlayers = players.filter(player => myTeam.ref.isEqual(player.teamRef));
      this.myTeamPlayers$.next(this.myTeamPlayers);
    });
    this.subscriptions.push(myTeamPlayers$);

    // MY ROLE
    const myRole$ = combineLatest([this.myPlayer$, this.roles$]).subscribe(([player, roles]) => {
      if (!player || !roles) {
        this.myRole = undefined;
        this.myRole$.next(undefined);
        return;
      }

      this.myRole = roles.find(role =>
        player.roleRef.isEqual(role.getReference())
      );
      if (this.myRole) {
        this.myRole$.next(this.myRole);
      }
    });
    this.subscriptions.push(myRole$);

    // MY ACTUAL TASKS
    this.myTasksActual$ = combineLatest([this.tasks$, this.myTeamPlayers$, this.myRole$])
      .pipe(
        map(([tasks, myTeamPlayers, myRole]) => {
          if (!myTeamPlayers || !myRole) {
            return [];
          }

          return tasks
            .filter(
              task => (task.isResolved(this.myTeamPlayers) && !task.isDone(myTeamPlayers) && task.roleRef.isEqual(myRole.ref))
            )
            .sort((a: Task, b: Task) => (a.order > b.order) ? 1 : -1);
        })
      );

    const myTasksActual$ = this.myTasksActual$.subscribe(tasks => {
      this.myTasksActual = tasks;
    });
    this.subscriptions.push(myTasksActual$);

    // MY TASKS DONE
    this.myTasksDone$ = combineLatest([this.tasks$, this.myTeamPlayers$, this.myPlayer$, this.myRole$])
      .pipe(
        map(([tasks, myTeamPlayers, myPlayer, myRole]) => {
          if (!myTeamPlayers || !myPlayer || !myRole) {
            return [];
          }

          return tasks
            .filter(
              task => (task.isDone(myTeamPlayers) && task.roleRef.isEqual(myRole.ref))
            )
            .sort((a: Task, b: Task) => (
              myPlayer.responses.getByID(a.id).createdAt.toMillis() < myPlayer.responses.getByID(b.id).createdAt.toMillis()) ? 1 : -1
            );
        })
      );

    const myTasksDone$ = this.myTasksDone$.subscribe(tasks => {
      this.myTasksDone = tasks;
    });
    this.subscriptions.push(myTasksDone$);

    this.afterInit$.next(true);
  }

  destroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.play = null;
    this.play$ = null;
    this.script = null;
    this.script$ = new Observable<Script>();
    this.workspace = null;
    this.workspace$ = null;
    this.organization = null;
    this.organization$ = new ReplaySubject<Organization>(1);
    this.myPlayer$ = new ReplaySubject<Player>(1);
    this.myPlayer = null;
    this.myRole$ = new ReplaySubject<Role>(1);
    this.myRole = null;
    this.myTeam$ = new ReplaySubject<Team>(1);
    this.myTeam = null;
    this.myTasksDone$ = new ReplaySubject<Array<Task>>(1);
    this.myTasksDone = null;
    this.tasks$ = new ReplaySubject<Array<Task>>(1);
    this.tasks = null;
    this.roles$ = new ReplaySubject<Array<Role>>(1);
    this.roles = null;

    this.afterInit$ = new ReplaySubject<boolean>();
  }

  async alertLog(message: string) {
    const alert = await this.alertController.create({
      header: 'Log',
      subHeader: new Date().toString(),
      message: message,
      buttons: ['OK']
    });

    await alert.present();
  }

}
