import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { User } from '../models/user.model';

@Component({
  selector: 'app-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.scss'],
})
export class ProfileMenuComponent implements OnInit {
  user: User;
  constructor(private router: Router, public authService: AuthService) { }

  async ngOnInit() {
    this.user = await this.authService.user$.pipe(first()).toPromise();
  }

  openProfile() {
    this.router.navigateByUrl('/user-profile');
  }

  openHelpCenter() {
    window.open('https://intercom.help/gamoteca/en/')
  }

  async logout() {
    await this.authService.signOut();
    await this.router.navigateByUrl('/welcome');
    window.location.reload();
  }
}
