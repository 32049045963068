import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { Play } from 'src/app/core/models/play.model';
import { ModelProvider } from 'src/app/core/models/general/model.provider';
import { IonInfiniteScroll, ModalController } from '@ionic/angular';
import { PlayListPageAnalyticsService } from './play-list-page.analytics';
import { Workspace } from 'src/app/core/models/workspace.model';
import { first } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, ReplaySubject, combineLatest } from 'rxjs';
import { WorkspaceService } from 'src/app/core/workspace/workspace.service';
import { Script } from 'src/app/core/models/script.model';
import { Storage } from '@ionic/storage';
import {SessionArchiveConfirmationDialogComponent} from "../list/session-archive-confirmation-dialog/session-archive-confirmation-dialog.component";

export const LOADING_LIMIT = 25;

@Component({
  selector: 'app-list',
  templateUrl: './play-list-page.component.html',
  styleUrls: ['./play-list-page.component.scss']
})
export class PlayListPageComponent implements OnInit, OnDestroy {
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;

  public plays: Array<Play> = [];
  private lastVisible: any;
  public defaultAvatarUrl = 'https://firebasestorage.googleapis.com/v0/b/gamoteca-161414.appspot.com/o/users%2Ffacilitator%2Favatar.png?alt=media&token=5265ea4d-525a-4212-873c-96aa13c9b649';
  public workspace: Workspace;
  private subscriptions: Subscription[] = [];
  public typeFilter$ = new ReplaySubject<'organized-game' | 'public-game'>(1);

  public scripts: Script[] = [];

  public showArchivedSessions = false;

  constructor(
    private modelProvider: ModelProvider,
    public analytics: PlayListPageAnalyticsService,
    private route: ActivatedRoute,
    private router: Router,
    public workspaceService: WorkspaceService,
    private modalController: ModalController,
    public storage: Storage
  ) {
  }

  async ionViewWillEnter() {
    this.scrollCompleted = false;
    this.analytics.setScreen();
  }

  async ngOnInit() {
    let playsSubscription: Subscription = null;
    let filterSubscription: Subscription = null;
    let scriptSubscription: Subscription = null;

    const workspaceSubscription = this.workspaceService.activatedWorkspace.subscribe(async workspace => {
      if(workspace.id === 'demo-games') {
        await this.router.navigate(['/design']);
        return;
      }
      this.typeFilter$.next('organized-game');
      this.workspace = workspace;

      if(this.infiniteScroll) { this.infiniteScroll.disabled = false; }
      this.scrollCompleted = true;
      this.scrollCompleted = false;

      const lastViewedWorkspacePath = await this.storage.get('session-list-workspace');
      if (lastViewedWorkspacePath === workspace.ref.path) {
        const lastViewedSessionType = await this.storage.get('session-list-type');
        if (lastViewedSessionType) {
          this.typeFilter$.next(lastViewedSessionType);
        }
      } else {
        await this.storage.set('session-list-workspace', this.workspace.ref.path);
      }

      if (playsSubscription) {
        playsSubscription.unsubscribe();
      }

      if (filterSubscription) {
        filterSubscription.unsubscribe();
      }

      if (scriptSubscription) {
        scriptSubscription.unsubscribe();
      }

      scriptSubscription =
        combineLatest(
          this.modelProvider.script.findAllByWorkspace(workspace),
          this.typeFilter$
        )
          .subscribe(([scripts, typeFilter]) => {
            this.scripts = scripts;
          });


      filterSubscription = this.typeFilter$.subscribe(playType => {
        if(this.infiniteScroll) { this.infiniteScroll.disabled = false; }
        this.scrollCompleted = true;
        this.scrollCompleted = false;

        playsSubscription = this.modelProvider.play.findAllByWorkspace(
          this.workspace,
          ref => ref
            .where('type', '==', playType)
            .orderBy('createdAt', 'desc')
            .limit(LOADING_LIMIT)
        ).subscribe(plays => {
          this.plays = plays;
          if (plays.length > 0) {
            this.lastVisible = plays[plays.length - 1].snapshot;
          }
        });
        this.subscriptions.push(playsSubscription);
      });

      this.subscriptions.push(filterSubscription);
    });
    this.subscriptions.push(workspaceSubscription);
  }

  async setTypeFilter(type: 'organized-game' | 'public-game') {
    await this.storage.set('session-list-type', type);
    this.typeFilter$.next(type);
  }

  getOpenURL(play) {
    // Public game
    if (play.type === 'public-game') {
      if (play.status == 'pre-start') {
        return null;
      } else {
        return ['/organize', play.id, 'live']
      }
    }

    // Organized game
    if (play.type === 'organized-game') {
      if (play.status == 'pre-start') {
        return ['/organize', play.id, 'edit'];
      } else {
        return ['/organize', play.id, 'live'];
      }
    }
  }

  openPlay(play: Play) {
    const url = this.getOpenURL(play);
    if (this.getOpenURL(play)) {
      this.router.navigate(url);
    }
  }

  public scrollCompleted = false;

  public onScroll(event): void {
    this.typeFilter$.pipe(first()).subscribe(playType => {
      const subscription = this.modelProvider.play.findAllByWorkspace(
        this.workspace,
        ref => ref
          .orderBy('createdAt', 'desc')
          .where('type', '==', playType)
          .startAfter(this.lastVisible)
          .limit(LOADING_LIMIT)
      ).subscribe(plays => {
        const lastPlay = plays[plays.length - 1];
        if (plays.length === 0) {
          this.lastVisible = null;
          this.infiniteScroll.disabled = true;
          this.scrollCompleted = true;
        } else {
          this.lastVisible = lastPlay.snapshot;
          this.plays = this.plays.concat(plays);
          event.target.complete();
        }
      });
      this.subscriptions.push(subscription);
    })
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  async archiveSession(event: any,play: Play) {
    event.stopPropagation();
    const successAction = 'archive';
    const cancelAction = 'cancel';
    const modal = await this.modalController.create({
      component: SessionArchiveConfirmationDialogComponent,
      componentProps: {
        title: "play-list/archive-session/confirmation-dialog/title",
        confirmQuestion: "play-list/archive-session/confirmation-dialog/confirm-question",
        confirmDescription: "play-list/archive-session/confirmation-dialog/confirm-description",
        cancelBtnText: "play-list/archive-session/confirmation-dialog/cancel",
        confirmBtnText: "play-list/archive-session/confirmation-dialog/archive",
        cancelAction,
        successAction
      },
      cssClass: [
        'auto-height',
        'sm-modal'
      ]
    });
    modal.onDidDismiss().then(async (dismissed) => {
      if (dismissed.data && dismissed.data.action) {
        if (dismissed.role === 'backdrop') { }
        switch (dismissed.data.action) {
          case cancelAction:
            break;

          case successAction:
              play.isArchived = true;
              await play.save();
            break;

          default:
            break;
        }
      }
    });
    await modal.present();
  }
  async unarchiveSession(event: any,play: Play) {
    event.stopPropagation();
    const successAction = 'unarchive';
    const cancelAction = 'cancel';
    const modal = await this.modalController.create({
      component: SessionArchiveConfirmationDialogComponent,
      componentProps: {
        title: "play-list/unarchive-session/confirmation-dialog/title",
        confirmQuestion: "play-list/unarchive-session/confirmation-dialog/confirm-question",
        confirmDescription: "play-list/unarchive-session/confirmation-dialog/confirm-description",
        cancelBtnText: "play-list/unarchive-session/confirmation-dialog/cancel",
        confirmBtnText:"play-list/unarchive-session/confirmation-dialog/unarchive",
        cancelAction,
        successAction
      },
      cssClass: [
        'auto-height',
        'sm-modal'
      ]
    });
    modal.onDidDismiss().then(async (dismissed) => {
      if (dismissed.data && dismissed.data.action) {
        if (dismissed.role === 'backdrop') { }
        switch (dismissed.data.action) {
          case cancelAction:
            break;

          case successAction:
              play.isArchived = false;
              await play.save();
            break;

          default:
            break;
        }
      }
    });
    await modal.present();
  }
  toggleArchivedSessions(event: any) {
    this.showArchivedSessions = !this.showArchivedSessions;
  }  
  getFilteredPlays() {
    if (this.showArchivedSessions) {
      return this.plays;
    } else {
      return this.plays.filter(play => !play.isArchived);
    }
  }
}
