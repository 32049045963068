<app-new-team-sidebar active="superadmin"></app-new-team-sidebar>
<ion-content>
  <app-profile-menu></app-profile-menu>
  <content-container class="py-5 px-3" [back-button]="'/superadmin/dashboard'" pageTitle="Teams">
    <ion-button class="top-right" shape="round" (click)="createTeam()">Create Team</ion-button>
    <ion-searchbar [disabled]="isDataFetching" showClearButton="focus" debounce="700" (ionChange)="handleSearch($event)"></ion-searchbar>
    <div *ngIf="isDataFetching; else finalData">
      <div class="spinnerCenter">
        <ion-spinner color="primary"></ion-spinner>
      </div>
    </div>
    <ng-template #finalData>
      <div class="no-data" *ngIf="organizations.length === 0">No records found</div>
      <div *ngIf="organizations.length > 0">
        <div class="row head mt-5 mb-4 text-uppercase">
          <div class="col-4">Team</div>
          <div class="col-2">Plan</div>
          <div class="col-2">Status</div>
          <div class="col-2">Created at</div>
          <div class="col-2"></div>
        </div>
        <div *ngFor="let organization of organizations" class="row my-3 mb-5">
          <ng-container *ngIf="organization && organization.plan">
            <div class="col-4">{{ organization?.title }}</div>
            <div class="col-2">{{ organization.plan?.title }}</div>
            <div class="col-2">{{ organization.plan?.status }}</div>
            <div class="col-2">{{ organization?.createdAt?.toDate() | date:'shortDate' }}</div>
            <div class="col-2 text-center">
              <context-menu-icon>      
                <context-item label="Edit plan" (click)="editOrganization(organization)"></context-item>
                <context-item label="Settings as user" (click)="settingsOrganization(organization)"></context-item>
              </context-menu-icon>
            </div>
          </ng-container>
        </div>
        <ion-infinite-scroll threshold="1px" (ionInfinite)="loadMore($event)">
          <ion-infinite-scroll-content
            loadingSpinner="bubbles">
          </ion-infinite-scroll-content>
        </ion-infinite-scroll>
      </div>
    </ng-template>
  </content-container>
</ion-content>