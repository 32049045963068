<div class="accordion-item">
  <div class="accordion-item-header" [ngStyle]="{'justify-content': isSideBarOpen ? 'flex-start' : 'center'}"  [ngClass]="{ 'accordion-closed': !isThisAccordionOpened }"  (click)="toggleAccordion()">
    <img [src]="(workspace._image | size : 'xs' : 'xs' : workspace.photoURL) | safe : 'resourceUrl'" />
    <p class="workspace-title" *ngIf="isSideBarOpen">{{workspace.title}}</p>
    <ion-icon *ngIf="isSideBarOpen && isThisAccordionOpened" name="chevron-up-outline"></ion-icon>
    <ion-icon *ngIf="isSideBarOpen && !isThisAccordionOpened" name="chevron-down-outline"></ion-icon>
  </div>
  <div [ngStyle]="{'max-height': isThisAccordionOpened ? '500px' : '0'}" class="accordion-item-body">
    <div [routerLink]="['/design']" [class.active]="active == 'design'" class="accordion-item-body-option">
      <mat-icon slot="icon-only">category</mat-icon>
      <span *ngIf="isSideBarOpen">Designs</span>
    </div>
    <div *ngIf="!isFeatured" [routerLink]="['/organize']" [class.active]="active == 'organize'" class="accordion-item-body-option">
      <mat-icon slot="icon-only">auto_awesome_motion</mat-icon>
      <span *ngIf="isSideBarOpen">Sessions</span>
    </div>
    <div *ngIf="!isFeatured" [routerLink]="['/analytics']" [class.active]="active == 'analytics'" class="accordion-item-body-option">
      <mat-icon slot="icon-only">bar_chart</mat-icon>
      <span *ngIf="isSideBarOpen">Analytics</span>
    </div>
    <div *ngIf="!isFeatured" [routerLink]="['/teams/settings']" [class.active]="active == 'settings'" class="accordion-item-body-option">
      <mat-icon slot="icon-only">settings</mat-icon>
      <span *ngIf="isSideBarOpen">Settings</span>
    </div>
  </div>
</div>