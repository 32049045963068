import { Component, Input, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-nav-footer',
  templateUrl: './nav-footer.component.html',
  styleUrls: ['./nav-footer.component.scss'],
})
export class NavFooterComponent implements OnInit {
  @Input() isSideBarOpen: boolean;
  @Input() active: string;
  isSuperadmin = false;
  constructor(public authService: AuthService,) { }

  async ngOnInit() {
    const authUser = await this.authService.afAuth.user.pipe(first()).toPromise();
    const tokenResult = await authUser.getIdTokenResult();
    this.isSuperadmin = tokenResult.claims.superadmin;
  }
  isPlayLandingPageVisible(): boolean {
    return localStorage.getItem('is-play-landing-page-visible') ?
      localStorage.getItem('is-play-landing-page-visible') === 'true' :
      false;
  }
}
