import { Component, ViewChild, Output, EventEmitter, ChangeDetectorRef, Input } from '@angular/core';
import { MediaCapture, MediaFile, CaptureError } from '@ionic-native/media-capture/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { File as NativeFile } from '@ionic-native/file/ngx';
import { VideoEditor } from '@ionic-native/video-editor/ngx';
import { Subject } from 'rxjs';
import { Platform } from '@ionic/angular';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { VideoRecordMobileAnalyticsService } from './video-record-mobile.analytics';
import { Storage } from '@ionic/storage';
import { FilePath } from '@ionic-native/file-path/ngx';
import { InputVideoLayoutItem } from 'src/app/core/models/layout-item-input-video';
import { TranslateService } from '@ngx-translate/core';

/**
 * Generated class for the VideoMobileComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'video-record-mobile',
  templateUrl: './video-record-mobile.html',
  styleUrls: ['./video-record-mobile.scss'],
  providers: [
    MediaCapture,
    AndroidPermissions,
    VideoEditor,
    WebView,
    AndroidPermissions,
    NativeFile,
    VideoRecordMobileAnalyticsService
  ]
})
export class VideoRecordMobileComponent {
  @ViewChild('captureVideo') captureVideo;
  @ViewChild('previewVideo') previewVideo;

  @Output('file') file: EventEmitter<File | Blob> = new EventEmitter<File | Blob>();
  @Output('onTextTyped') onTextTyped: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  public layoutItem: InputVideoLayoutItem;
  @Input()
  public language = 'en';

  public hasInfo = false;
  public alternateOptions: Array<string> = [];
  public showAlternates = true;
  public activeAlternate: string | boolean = false


  public status: string = 'inactive';
  public transcodeProgress = null;
  public progressPercent: Subject<Number> = new Subject<Number>();
  public isLoading: boolean = false;

  @Input()
  cacheKey: string;

  constructor(
    private mediaCapture: MediaCapture,
    private platform: Platform,
    private androidPermissions: AndroidPermissions,
    private nativeFile: NativeFile,
    private videoEditor: VideoEditor,
    private cdr: ChangeDetectorRef,
    private analytics: VideoRecordMobileAnalyticsService,
    public storage: Storage,
    private filePath: FilePath,
    public translate: TranslateService
  ) {
  }

  async ngOnInit() {
    this.hasInfo = this.layoutItem.getInstructionText()?.length > 0;
    this.alternateOptions = this.layoutItem.properties && this.layoutItem.properties.alternate &&
      Object.keys(this.layoutItem.properties.alternate).filter(key => this.layoutItem.properties.alternate[key].active);
  }

  async ngAfterViewInit() {
    if (this.cacheKey) {
      this.analytics.debug('cache exists', { key: this.cacheKey });
      const self = this;
      try {
        const cachedFilePath: string = await this.storage.get(this.cacheKey);
        this.analytics.debug('cache - path', { path: cachedFilePath });
        if (cachedFilePath) {
          const dir = cachedFilePath.split("/").slice(0, -1).join("/");
          const filename = cachedFilePath.replace(/^.*[\\\/]/, '');
          this.nativeFile.readAsArrayBuffer(dir, filename).then(arrayBuffer => {
            let blob = new Blob([arrayBuffer], { type: 'video/mp4' });

            (blob as any).name = filename;
            self.file.emit(blob);

            var url = URL.createObjectURL(blob);
            self.previewVideo.nativeElement.src = url;
            if (this.platform.is('ios')) {
              this.previewVideo.nativeElement.autoplay = true;
              const playPromise = this.previewVideo.nativeElement.play();
              if (playPromise !== undefined) {
                playPromise.then(_ => {
                  this.previewVideo.nativeElement.pause();
                }).catch(error => {
                  console.log(error);
                });
              } else {
                this.previewVideo.nativeElement.pause();
              }
            }
            this.status = 'success';
          }).catch(error => {
            console.log(error);
          });
        }
      } catch (error) {
      }
    }
  }

  public cancel() {
    this.status = 'inactive';
    this.file.emit(null);
  }

  public retake() {
    this.analytics.onRetakeClick();
    this.cancel();
    this.capture();
  }

  private async afterAndroidPermission() {
    return new Promise((resolve, reject) => {
      this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE).then(
        result => {
          this.analytics.debug('afterAndroidPermission - check', { result: JSON.stringify(result) });
          if (!result.hasPermission) {
            this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE).then(result => {
              this.analytics.debug('afterAndroidPermission - request - Permission1', { result: JSON.stringify(result) });
              if (!result.hasPermission) {
                reject(false);
              } else {
                resolve(true);
              }
            })
          } else {
            resolve(true);
          }
        },
        err => this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE).then(result => {
          this.analytics.debug('afterAndroidPermission - request - Permission2', { result: JSON.stringify(result) });
          if (!result.hasPermission) {
            reject(false);
          } else {
            resolve(true);
          }
        })
      );
    })

  }

  public onTranscodeProgress(info) {
    this.progressPercent.next(Math.round(info * 100));
  }

  public async transcodeiOS(path) {
    const self = this;
    return this.videoEditor.transcodeVideo({
      fileUri: path,
      outputFileName: "capture-" + new Date().getTime() + "-" + this.platform.platforms().join("-") + "-transcoded",//'capture-' + new Date().getTime(),
      outputFileType: this.videoEditor.OutputFileType.M4V,
      optimizeForNetworkUse: this.videoEditor.OptimizeForNetworkUse.YES, // ios only
      saveToLibrary: false, // optional, defaults to true
      deleteInputFile: true, // optional (android only), defaults to false
      // progress: this.onTranscodeProgress // info will be a number from 0 to 100
      progress: (percent) => {
        self.progressPercent.next(Math.ceil(percent));
        self.cdr.detectChanges();
      }
    })
      .then((fileUri: string) => {
        return fileUri;
      })
      .catch((error: any) => {
        return error;
      });
  }

  public async transcodeAndroid(path) {
    const self = this;
    this.analytics.debug('transcodeAndroid - call', { path: path });
    const transcodePercents = [25, 50, 75, 100];
    const transcodePercentProgress = {
      25: false,
      50: false,
      75: false,
      100: false,
    };

    return this.videoEditor.transcodeVideo({
      fileUri: path,
      outputFileName: "capture-" + new Date().getTime() + "-" + this.platform.platforms().join("-") + "-transcoded",//'capture-' + new Date().getTime(),
      outputFileType: this.videoEditor.OutputFileType.M4V,
      optimizeForNetworkUse: this.videoEditor.OptimizeForNetworkUse.YES, // ios only
      saveToLibrary: false, // optional, defaults to true
      deleteInputFile: true, // optional (android only), defaults to false
      maintainAspectRatio: true, // optional (ios only), defaults to true
      width: 800, // optional, see note below on width and height
      height: 800, // optional, see notes below on width and height
      videoBitrate: 1000000, // optional, bitrate in bits, defaults to 1 megabit (1000000)

      // fps: 24, // optional (android only), defaults to 24
      audioChannels: 2, // optional (ios only), number of audio channels, defaults to 2
      audioSampleRate: 44100, // optional (ios only), sample rate for the audio, defaults to 44100
      audioBitrate: 128000, // optional (ios only), audio bitrate for the video in bits, defaults to 128 kilobits (128000)
      progress: (percent) => {
        const percentRound = Math.ceil(
          this.platform.is('android') ? percent * 100 : percent
        );

        for (const percent of transcodePercents) {
          if (percentRound >= percent && transcodePercentProgress[percent] === false) {
            transcodePercentProgress[percent] = true;
            this.analytics.debug('transcodeAndroid - progress - ' + percent + '%');
          }
        }

        self.progressPercent.next(percentRound);
        self.cdr.detectChanges();
      }
    })
      .then((fileUri: string) => {
        this.analytics.debug('transcodeAndroid - success', { fileUri: fileUri });
        return fileUri;
      })
      .catch((error: any) => {
        this.analytics.debug('transcodeAndroid - error', { error: JSON.stringify(error) });
        throw new Error(error);
      });
  }

  public async capture() {
    this.analytics.onRecordClick();
    let self = this;

    this.mediaCapture.captureVideo()
      .then(
        async (data: MediaFile[]) => {
          this.analytics.onStopClick();
          self.status = 'success';
          let file = data[0];

          this.analytics.debug('fullPath', { path: file.fullPath });

          let path2 = self.platform.is("ios") ? 'file://' + file.fullPath : file.fullPath;

          if (self.platform.is('android') && file.fullPath.startsWith('content://')) {
            path2 = await this.filePath.resolveNativePath(path2)
          }

          this.analytics.debug('path2', { path: path2 });

          let dir = path2.split("/").slice(0, -1).join("/");
          let filename = path2.replace(/^.*[\\\/]/, '');

          let transcodedPath: string;
          try {
            this.progressPercent.next(1);
            this.cdr.detectChanges();
            if (self.platform.is("android")) {
              await this.afterAndroidPermission();
              transcodedPath = await this.transcodeAndroid(path2);
            } else {
              transcodedPath = await this.transcodeiOS(path2);
            }
            this.progressPercent.next(null);

            transcodedPath = self.platform.is("ios") ? "file://private" + transcodedPath : "file://" + transcodedPath;
            this.analytics.debug('capture - transcodedPath', { transcodedPath: transcodedPath });

            dir = transcodedPath.split("/").slice(0, -1).join("/");
            filename = transcodedPath.replace(/^.*[\\\/]/, '');
          } catch (error) {
            this.analytics.debug('capture - transcodedPath - error', { error: JSON.stringify(error) });
            this.progressPercent.next(null);
            this.cdr.detectChanges();
          }

          this.analytics.debug('capture - readAsArrayBuffer - init', { dir: dir, fileName: filename });
          this.isLoading = true;
          this.nativeFile.readAsArrayBuffer(dir, filename).then(arrayBuffer => {
            let blob = new Blob([arrayBuffer], { type: 'video/mp4' });

            (blob as any).name = filename;
            (blob as any).filePath = transcodedPath;
            self.file.emit(blob);

            this.analytics.debug('capture - readAsArrayBuffer - succeed', { filename: filename, size: blob.size });

            var url = URL.createObjectURL(blob);
            this.isLoading = false;
            this.previewVideo.nativeElement.src = url;

            this.previewVideo.nativeElement.autoplay = true;
            const playPromise = this.previewVideo.nativeElement.play();
            if (playPromise !== undefined) {
              playPromise.then(_ => {
                this.previewVideo.nativeElement.pause();
              }).catch(error => {
              });
            } else {
              this.previewVideo.nativeElement.pause();
            }
          }).catch(error => {
            this.analytics.debug('capture - error1', { error: JSON.stringify(error) });
          });
        },
        (err: CaptureError) => {
          this.analytics.debug('capture - error2', { error: JSON.stringify(err) });
          console.error(err)
        }
      );
  }

  public onShowAlternates() {
    this.showAlternates = true;
    this.analytics.onShowAlternateOptions();
  }

  public setActiveAlternate(alternate: string | boolean) {
    this.activeAlternate = alternate;
    if (alternate) {
      this.analytics.onClickAlternateOption(this.translate.instant('media-capture/video/alternate/' + alternate + '/label'), alternate as string);
    } else {
      this.onTextTyped.emit('');
      this.analytics.onBackToVideo(this.translate.instant('media-capture/video/alternate/back-to-video/label'));
    }
  }

}
