import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-nav-header',
  templateUrl: './nav-header.component.html',
  styleUrls: ['./nav-header.component.scss'],
})
export class NavHeaderComponent implements OnInit {
  @Input() isSideBarOpen: boolean;
  @Output() toggleSidebarEvent = new EventEmitter();

  toggleSidebar() {
    this.toggleSidebarEvent.emit();
  }

  async ngOnInit() {

  }

  constructor() { }
}
