<div class="nav-header">
  <a class="logo" slot="start" [routerLink]="['']">
    <span class="logo-container">
      <img *ngIf="isSideBarOpen" src="../../../assets/layout/header/logo_horizontal_color.png" class="logo-image"/>
      <img *ngIf="!isSideBarOpen" src="../../../../assets/images/gamoteca-small-logo.png" class="logo-image"/>
    </span>
  </a>
</div>
<div id="nav-toggle-burger" (click)="toggleSidebar()">
  <mat-icon class="" *ngIf="isSideBarOpen" slot="icon-only">keyboard_double_arrow_left</mat-icon>
  <mat-icon *ngIf="!isSideBarOpen" slot="icon-only">keyboard_double_arrow_right</mat-icon>
</div>