<app-new-team-sidebar active="organize"></app-new-team-sidebar>
<ion-content>
  <app-profile-menu></app-profile-menu>
  <div class="container py-3">
    <h2 *ngIf="workspace?.isPersonal">{{'workspace/personal/default-title' | translate}}</h2>
    <h2 *ngIf="!workspace?.isPersonal">{{workspace.title}}</h2>
    <ul class="tab p-0 m-0 mt-4 mb-5 d-flex justify-content-between align-items-center">
      <div class="d-flex">
        <li (click)="setTypeFilter('organized-game')" class="d-inline-block" [class.active]="(typeFilter$ | async) === 'organized-game'">{{'play-list/tab/organized-game' | translate}}</li>
        <li (click)="setTypeFilter('public-game')"class="d-inline-block" [class.active]="(typeFilter$ | async)  === 'public-game'">{{'play-list/tab/public-game' | translate}}</li>
      </div>
      <li style="margin-right: 0;" class="d-flex align-items-center">
        <label (click)="toggleArchivedSessions($event)" class="d-flex align-items-center" style="cursor: pointer;">
          <span class="archive-title mr-3">Show archived session</span>
          <ion-checkbox [checked]="showArchivedSessions"></ion-checkbox>
        </label>
      </li>
    </ul>

    <div class="no-sessions" *ngIf="getFilteredPlays().length === 0">
      <p>No sessions available</p>
    </div>

    <span *ngIf="getFilteredPlays().length > 0">
      <div
      *ngFor="let play of getFilteredPlays()">
        <span 
        [dir]="(play.scriptVersion$ | async)?.direction || 'ltr'"
        (click)="analytics.onPlayClick(play); openPlay(play);"
        [ngStyle]="{'cursor': getOpenURL(play) ? 'pointer': 'default'}"
        [ngClass]="{
          'red': play.isArchived === true && showArchivedSessions,
          'row': true,
          'event-card': true,
          'rtl-play-title': (play.scriptVersion$ | async)?.direction === 'rtl' || false,
          'ltr-play-title': (play.scriptVersion$ | async)?.direction === 'ltr' || ( (play.scriptVersion$ | async)?.direction !== 'rtl' && true)
        }">
          <div class="col-2 event-image"
                [ngStyle]="{'background-image': ('url('+ (play?.scriptVersion?._image | size : 'sm' : 'sm' : '/assets/images/img-placeholder.png') +')')  }">
          </div>

          <div class="col-2 play-title rtl-text">{{ play.title }}</div>

          <div class="col-2 created-on">
            <div class="avatars">
              <img [src]="defaultAvatarUrl"
                    [alt]="'general/created-on' | translate"/>
            </div>
            <div>{{ 'general/created-on' | translate }}</div>
            <div>{{ play.createdAt?.toDate() | date: 'dd/MM/YY' }}</div>
          </div>

          <div class="col-2 player-slots flex-column">
              <div class="d-flex align-items-center justify-content-center">
                <mat-icon>people</mat-icon>
                <span>{{ play.slots?.enrolled }}/{{ play.slots?.total }}</span>
              </div>
              <ion-badge *ngIf="play.accessLevel === 'private'" class="d-flex" size="small" color="warning">
                <ion-icon name="shuffle"></ion-icon>
                Random
              </ion-badge>
          </div>

          <div class="col-2 status text-center">
            <div class="label">
              {{ 'play-list/table/header/status/' + play.status | translate }}
            </div>
          </div>
          <div class = "col-2 archive">
            <button *ngIf="!play.isArchived" (click)="archiveSession($event,play)" class = "btn"><ion-icon name="trash-outline"></ion-icon></button>
            <button *ngIf="play.isArchived" (click)="unarchiveSession($event, play)" class="btn">
              <ion-icon name="arrow-undo-circle-outline"></ion-icon>
            </button>
          </div>
        </span>
      </div>
    </span>
    <ion-infinite-scroll *ngIf="!scrollCompleted" threshold="1px"
                        (ionInfinite)="onScroll($event)">
      <ion-infinite-scroll-content loadingSpinner="bubbles"></ion-infinite-scroll-content>
    </ion-infinite-scroll>
  </div>
</ion-content>
