import { LayoutItem } from './task-layout-item';
import { every, isEmpty, mapValues, max, values } from 'lodash-es';
import { FirestoreField } from '../firebase/firestore-field';
import { ModelProvider } from './general/model.provider';
import { Task } from './task.model';

export class Choice extends FirestoreField {
    id: string;
    order: number;
    languages?: {
        [languageID: string]: string;
    };

    public rules() {
        return {
            id: {},
            order: {},
            languages: {}
        };
    }

    public get text() {
        return this.modelProvider.localize.getText(this);
    }

    public set text(text: string) {
        this.modelProvider.localize.setText(this, text);
    }

    public toData() {
        const data = super.toData();
        delete data['id'];
        return data;
    }
}

export class InputChoiceLayoutItem extends LayoutItem {
    public type: string = LayoutItem.TYPE_INPUT_CHOICE;
    public order = 60;

    public properties: {
        question: {
            languages?: {
                [languageID: string]: string;
            },
        },
        choices: {
            [choiceID: string]: {
                order?: number,
                languages?: {
                    [languageID: string]: string;
                },
            }
        }
    };

    private _choices: {
        [choiceID: string]: Choice
    };
    public get choices() {
        return this._choices;
    }

    public setFields(data: any) {
        super.setFields(data);

        this._choices = {};
        for (const choiceID in data.properties.choices) {
            if (data.properties.choices.hasOwnProperty(choiceID)) {
                const choice = data.properties.choices[choiceID];
                this._choices[choiceID] = new Choice({ id: choiceID, ...choice }, this.documentInstance, this.options, this.modelProvider);
            }
        }
        this._choices = { ...this._choices };
    }

    public toData() {
        return {
            type: this.type,
            order: this.order,
            properties: {
                question: this.properties.question,
                choices: mapValues(this._choices, (choice) => {
                    return choice.toData();
                })
            },
        };
    }

    public get question() {
        return this.modelProvider.localize.getText(this.properties.question);
    }

    public set question(text: string) {
        this.properties.question = this.modelProvider.localize.setText(this.properties.question, text);
    }

    public addChoice(text: string = '') {
        const choiceID = this.modelProvider.fsDB.createId();
        const order = max(values(mapValues(this.choices, 'order'))) + 1 || 0;
        this._choices[choiceID] = new Choice(
            { id: choiceID, order: order, text: text },
            this.documentInstance,
            { withModelData: true },
            this.modelProvider
        );

        return this._choices[choiceID];
    }

    public removeChoice(choice: Choice) {
        delete this._choices[choice.id];
    }

    public validate() {
        return this.hasEnoughAnswers() && this.hasQuestion() && this.answersAreFilled();
    }

    public get isValid() {
        return this.validate();
    }

    public hasEnoughAnswers() {
        return Object.values(this.choices).length > 0;
    }

    public hasQuestion() {
        return !isEmpty(this.question);
    }

    public answersAreFilled() {
        return every(Object.values(this.choices), (choice: any) => !isEmpty(choice.text));
    }

    // tslint:disable-next-line: member-ordering
    public static instantiate(data: any,
        documentInstance?: Task,
        options?: any,
        modelProvider?: ModelProvider): LayoutItem {
        return new InputChoiceLayoutItem(data, documentInstance, options, modelProvider);
    }
}
