import { DocumentReference } from '@firebase/firestore-types';
import { FirestoreModel } from '../firebase/firestore-model';
import { FirestoreMapField } from '../firebase/firestore-map-field';
import { Task } from './task.model';
import { Observable, Subject } from 'rxjs';
import { Team } from './team.model';
import { Role } from './role.model';
import { Play } from './play.model';
import { User } from './user.model';
import { ScriptVersion } from './script-version.model';
import { Response } from './response';
import { map, take, tap, first, shareReplay } from 'rxjs/operators';
import { Model } from './general/model';
import firebase from "firebase/app"
import { QueryGroupFn } from '@angular/fire/firestore';
import { Script } from './script.model';

export class Player extends FirestoreModel<Player> {
  static STATUS_INVITED = 'invited';
  static STATUS_ACTIVE = 'active';
  static STATUS_FINISHED = 'finished';

  public COLLECTION_NAME = 'players';

  public _responses: FirestoreMapField<Response>;
  public get responses() {
    if (!this._responses) {
      this._responses = new FirestoreMapField<Response>({}, this, {}, this.modelProvider);
    };
    return this._responses;
  }
  public set responses(responses) {
    this._responses = responses;
  }

  public status: string;
  public userRef: DocumentReference;
  public teamRef: DocumentReference;
  public roleRef: DocumentReference;
  public legacy: {
    roleID: string;
  };
  public scriptRef: DocumentReference;
  public scriptVersionRef: DocumentReference;
  public playRef: DocumentReference;
  public type: 'player' | 'facilitator';
  public progress: number;

  protected rules() {
    return {
      status: {},
      userRef: {},
      teamRef: {},
      roleRef: {},
      legacyRoleID: {},
      _responses: {
        [Model.RULE_ALIAS]: 'responses',
        [Model.RULE_CLASS]: FirestoreMapField,
        [Model.RULE_MAP_TO_CLASS]: Response
      },
      scriptRef: {},
      scriptVersionRef: {},
      playRef: {},
      type: {},
      progress: {}
    };
  }


  public isFinished(tasks: Task[]) {
    for (const [taskID, response] of Object.entries(this.responses.items)) {
      if (response.done) {
        const task = tasks.find(task => task.id === taskID);
        if (task.terminate) {
          return true;
        }
      }

    }

    return false;
  }

  public get score() {
    let score = 0;

    for (const response of this.responses.values) {
      if (response.done === true && response.point) {
        score += response.point;
      }
    }

    return score;
  }

  /**
   * Script version
   */
  // tslint:disable-next-line: member-ordering
  private _scriptVersionRef$: Observable<ScriptVersion>;

  public get scriptVersion$(): Observable<ScriptVersion> {
    if (this._scriptVersionRef$ !== undefined) { return this._scriptVersionRef$; }

    this._scriptVersionRef$ = this.modelProvider.scriptVersion.findByRef(this.scriptVersionRef);
    return this._scriptVersionRef$;
  }

  // tslint:disable-next-line: member-ordering
  private _scriptVersion: ScriptVersion;
  public get scriptVersion(): ScriptVersion {
    if (this._scriptVersion !== undefined) { return this._scriptVersion; }
    this._scriptVersion = null;

    this.scriptVersion$.subscribe(scriptVersion => {
      this._scriptVersion = scriptVersion;
    });
    return this._scriptVersion;
  }

  /**
   * Team
   */
  // tslint:disable-next-line: member-ordering
  private _team$: Observable<Team>;
  public get team$(): Observable<Team> {
    if (this._team$ !== undefined) { return this._team$; }

    this._team$ = this.modelProvider.team.findByRef(this.teamRef);
    return this._team$;
  }

  // tslint:disable-next-line: member-ordering
  private _team: Team;
  public get team(): Team {
    if (this._team !== undefined) { return this._team; }
    this._team = null;

    this.team$.subscribe(team => {
      this._team = team;
    });
    return this._team;
  }

  /**
   * Role
   */
  // tslint:disable-next-line: member-ordering
  private _role$: Observable<Role>;
  public get role$(): Observable<Role> {
    if (this._role$ !== undefined) { return this._role$; }

    this._role$ = this.modelProvider.role.findByRef(this.roleRef);
    return this._role$;
  }

  // tslint:disable-next-line: member-ordering
  private _role: Role;
  public get role(): Role {
    if (this._role !== undefined) { return this._role; }
    this._role = null;

    this.role$.subscribe(role => {
      this._role = role;
    });
    return this._role;
  }

  /**
   * Event
   */
  // tslint:disable-next-line: member-ordering
  private _play$: Observable<Play>;
  public get play$(): Observable<Play> {
    if (this._play$ !== undefined) { return this._play$; }

    this._play$ = this.modelProvider.play.findByRef(this.playRef);
    return this._play$;
  }

  // tslint:disable-next-line: member-ordering
  private _play: Play;
  public get play(): Play {
    if (this._play !== undefined) { return this._play; }
    this._play = null;

    this.play$.subscribe(play => {
      this._play = play;
    });
    return this._play;
  }

  /**
   * User
   */
  // tslint:disable-next-line: member-ordering
  private _user$: Observable<User>;
  public get user$(): Observable<User> {
    if (this._user$ !== undefined) { return this._user$; }
    this._user$ = this.modelProvider.user.findByRef(this.userRef);
    return this._user$;
  }

  // tslint:disable-next-line: member-ordering
  private _user: User;
  public get user(): User {
    if (this._user !== undefined) { return this._user; }
    this._user = null;


    const subscribe = this.user$.pipe(first()).subscribe(user => {
      this._user = user;
    });
    return this._user;
  }

  public findAllByPlay(play: Play) {
    return this.findAllBy(
      null,
      play.getDocument().collection<Play>('players').ref
    );
  }

  public findAllByPlayAndRole(play: Play, role: Role) {
    return this.findAllBy(
      ref => ref.where('roleRef', '==', role.getReference()),
      play.getDocument().collection<Play>('players').ref
    );
  }

  public findAllByTeam(team: Team) {
    return this.findAllBy(ref => ref.where('teamRef', '==', team.getDocument().ref));
  }

  public findAllByScript$(script: Script, queryFn?: QueryGroupFn): Observable<Array<Player>> {
    if (!queryFn) { queryFn = ref => ref; }

    const queryRaw = queryFn(
      firebase.firestore().collectionGroup('players').where('scriptRef', '==', script.ref)
    );

    return this.findAllBy(ref => queryRaw);
  }

  // private _allMy
  public findAllMy$(queryFn?: QueryGroupFn): Observable<Array<Player>> {
    if (!queryFn) { queryFn = ref => ref; }

    const queryRaw = queryFn(
      firebase.firestore().collectionGroup('players').where('userRef', '==', this.modelProvider.user.meReference)
    );

    return this.findAllBy(ref => queryRaw).pipe(shareReplay(1));
  }

  public getScore() {
    let score = 0;

    // tslint:disable-next-line:forin
    for (const response of this.responses.values) {
      // const response = this.responses.items[taskID];
      if (response.done === true && response.point) {
        score += response.point;
      }
    }

    return score;
  }

  // tslint:disable-next-line: member-ordering
  public onBeforeAddResponse$ = new Subject<{ task: Task, response: Response }>();
  // tslint:disable-next-line: member-ordering
  public onAfterAddResponse$ = new Subject<{ task: Task, response: Response }>();

  public addResponse(task: Task, response: Response) {
    this.onBeforeAddResponse$.next({
      task: task,
      response: response
    });
    response.createdAt = firebase.firestore.Timestamp.now();
    this.responses.add(task.id, response);
    this.onAfterAddResponse$.next({
      task: task,
      response: response
    });
  }

  public getResponse(task: Task): Response {
    if (!this.responses) {
      return undefined;
    }

    return this.responses.getByID(task.id);
  }

  public getLatestResponse() {
    return this.responses.values.filter(a => !!a.createdAt).sort((a,b) => a.createdAt?.toMillis() > b.createdAt?.toMillis() ? -1 : 1)[0];
  }

  protected instantiate(path, data, options?: any) {
    return new Player(path, data, options, this.modelProvider);
  }
}
