import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '../../pipes/pipes.module';
import { WorkspaceModule } from '../workspace.module';
import { PrivilegeModule } from '../../privilege/privilege.module';
import { MatIconModule } from '@angular/material/icon';
import { WorkspaceOrderPipe } from '../../pipes/workspace-order.pipe';
import { AnalyticsModule } from '../../analytics/analytics.module';
import { SafePipeModule } from 'safe-pipe';
import { NewTeamSidebarComponent } from './new-team-sidebar.component';
import { AccordianGroupComponent } from '../accordion-group/accordion-group.component';
import { AccordionItemComponent } from '../accordion-item/accordion-item.component';
import { NavHeaderComponent } from '../nav-header/nav-header.component';
import { NavFooterComponent } from '../nav-footer/nav-footer.component';
import { CompanyTileComponent } from '../company-tile/company-tile.component';
import { ContextMenuModule } from '../../context-menu/context-menu.module';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        IonicModule.forRoot(),
        TranslateModule.forChild(),
        PipesModule,
        WorkspaceModule,
        PrivilegeModule,
        MatIconModule,
        AnalyticsModule,
        SafePipeModule,
        ContextMenuModule
    ],
    exports: [
        NewTeamSidebarComponent,
        AccordianGroupComponent,
        AccordionItemComponent,
        NavHeaderComponent,
        NavFooterComponent,
        CompanyTileComponent
    ],
    declarations: [
        NewTeamSidebarComponent,
        AccordianGroupComponent,
        AccordionItemComponent,
        NavHeaderComponent,
        NavFooterComponent,
        CompanyTileComponent
    ],
    providers: [
        WorkspaceOrderPipe,
    ]
})
export class NewTeamSidebarModule { }
