import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ModelProvider } from "src/app/core/models/general/model.provider";
import { TranslateService } from "@ngx-translate/core";
import { Script } from "src/app/core/models/script.model";
import { Workspace } from "src/app/core/models/workspace.model";
import { ScripListAnalyticsService } from "./script-list-analytics-service";
import { combineLatest, ReplaySubject, Subscription } from "rxjs";
import { WorkspaceService } from "src/app/core/workspace/workspace.service";
import { first, map, shareReplay, switchMap, tap } from "rxjs/operators";
import { OrganizationService } from "src/app/organization/organization.service";
import { IntercomService } from "src/app/core/intercom/intercom.service";
import { PrivilegeService } from "src/app/core/privilege/privilege.service";
import { PRIVILEGES } from "src/app/core/privilege/privilege-roles";
import { IonInfiniteScroll, ModalController } from "@ionic/angular";
import { ScriptEmptyListComponent } from "./empty-list/empty-list.component";
import { AuthService } from "src/app/auth/auth.service";
import { LogoutRequiredModalComponent } from "src/app/organization/logout-required-modal/logout-required-modal.component";

@Component({
  selector: "app-script-list",
  templateUrl: "./script-list-page.component.html",
  styleUrls: ["./script-list-page.component.scss"],
})
export class ScriptListPageComponent implements OnInit {
  public isSideBarOpen: boolean = true;
  public allScript: Array<Script> = [];
  public scripts: Array<Script> = [];
  public workspace: Workspace = null;

  private subscriptions: Subscription[] = [];

  public processing = null;
  public readonly: boolean;
  public PRIVILEGES = PRIVILEGES;

  public isSuperadmin = false;
  public organizationId: string = null;
  public inviter: string = null;
  public invitee: string = null;
  public userExists: string = null;
  public level: string = null;

  loadLimit$ = new ReplaySubject<number>(1);

  constructor(
    private modelProvider: ModelProvider,
    private router: Router,
    private route: ActivatedRoute,
    public analytics: ScripListAnalyticsService,
    private translateService: TranslateService,
    public workspaceService: WorkspaceService,
    public organizationService: OrganizationService,
    public intercom: IntercomService,
    public priviledgeService: PrivilegeService,
    private modalController: ModalController,
    private authService: AuthService
  ) { }

  async ngOnInit() {
    this.route.queryParamMap.subscribe((params) => {
      const base64Data = params.get("invitationData");
      if (base64Data) {
        try {
          const decodedData = atob(base64Data);
          const params = new URLSearchParams(decodedData);

          this.organizationId = params.get("organizationID");
          this.inviter = params.get("inviter");
          this.invitee = params.get("invitee");
          this.level = params.get("level");
          this.userExists = params.get("userExists");

          if (this.organizationId && this.inviter && this.invitee && this.level && this.userExists) {
            this.authService.user$.pipe(first()).subscribe((user) => {
              const signedInUserEmail = user.email;

              if (signedInUserEmail !== this.invitee) {
                this.showAccountConflictPopup();
              } else {
                this.showAcceptInvitationPopup();
              }
            });
          } else {
            this.initializePage();
          }
        } catch (error) {
          this.initializePage();
        }
      } else {
        this.initializePage();
      }
    });
  }

  async initializePage() {
    this.analytics.setScreen();
    this.processing = "loading";

    this.isSuperadmin = await this.authService.isSuperAdmin();

    const workspaceSubscription = this.workspaceService.activatedWorkspace
      .pipe(
        tap((workspace) => (this.processing = "loading")),
        tap((workspace) => (this.workspace = workspace)),
        tap(workspace => {
          this.loadLimit$.next(10);
          this.scrollCompleted = false;
        }),
        switchMap((workspace) => combineLatest([
          workspace.scripts$.pipe(
            map((scripts) =>
              scripts.sort((a, b) => {
                return a.createdAt.toMillis() > b.createdAt.toMillis() ? -1 : 1;
              })
            ),
            tap(scripts => this.allScript = scripts),
            shareReplay(1)
          ),
          this.loadLimit$
        ])),
        map(([scripts, loadLimit]) => scripts.slice(0, loadLimit)),
        tap((scripts) => (this.processing = false))
      )
      .subscribe(async (scripts) => {
        (this.scripts = scripts),
          (this.readonly = !(await this.priviledgeService.hasAccess(
            PRIVILEGES.DESIGN_EDIT
          )));
      });

    // @ts-ignore
    window.isTourCancelled = true;
    this.subscriptions.push(workspaceSubscription)
  }
  async showAccountConflictPopup() {
    const currentUrl = this.router.url;
    const logoutModal = await this.modalController.create({
      component: LogoutRequiredModalComponent,
      cssClass: "logout-reqired auto-height",
    });
    await logoutModal.present();
    const { data } = await logoutModal.onDidDismiss();
    if (!data || data.action === 'cancel') {
      this.initializePage();
    } else if (data.action === 'logout') {
      await this.authService.signOut();
      await this.router.navigateByUrl(`/welcome?redirectUrl=${encodeURIComponent(currentUrl)}`);
      window.location.reload();
    }
  }

  async showAcceptInvitationPopup() {
    try{
      await this.organizationService.acceptInvitationToJoinOrganization(`/organizations/${this.organizationId}`, this.inviter, this.invitee, this.level, this.userExists );
      this.initializePage();
    }catch(error){
      this.initializePage();
    }
  }

  ngOnDestory(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  async addNewScript(isTourCancelled = true) {
    // @ts-ignore
    window.isTourCancelled = isTourCancelled;
    this.processing = "new-script";
    this.analytics.createAddNewScriptClickedEvent(this.workspace);

    try {
      const isScriptLimitValid =
        await this.workspaceService.activeWorkspace.isValidScriptLimit();
      if (!isScriptLimitValid) {
        throw new Error("script-limit-invalid");
        return;
      }

      const response = await this.modelProvider.functions
        .httpsCallable("createScript")({
          workspacePath: this.workspace.ref.path,
        })
        .pipe(first())
        .toPromise();

      if (response.result === "ok") {
        await this.router.navigate([
          "design",
          this.modelProvider.fsDB.doc(response.scriptPath).ref.id,
          "task",
        ]);
      }
    } catch (error) {
      if (error.message === "script-limit-invalid") {
        const organization =
          await this.workspaceService.activeWorkspace.ownerOrganization$
            .pipe(first())
            .toPromise();
        await this.organizationService.showScriptLimitWarning(organization);
      }
    } finally {
      this.processing = null;
    }
  }

  async openAI() {
    await this.router.navigateByUrl('/ai/create-game');
  }

  async showEmpty() {
    const modal = await this.modalController.create({
      component: ScriptEmptyListComponent,
      componentProps: {
        listPage: this,
      },
      cssClass: "script-empty-list auto-height",
    });
    return await modal.present();
  }

  trackById(index, item) {
    return item.id;
  }

  trackByKeyId(index, item) {
    return item.key.id;
  }

  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  public scrollCompleted = false;
  async onScroll(event) {
    let limit = (await this.loadLimit$.pipe(first()).toPromise());
    limit += 10;

    if (limit >= this.allScript.length) {
      this.scrollCompleted = true;
      event.target.disabled = true;
    }
    event.target.complete();
    this.loadLimit$.next(limit);
  }
}
