import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Workspace } from '../../models/workspace.model';

@Component({
  selector: 'app-accordion-item',
  templateUrl: './accordion-item.component.html',
  styleUrls: ['./accordion-item.component.scss'],
})
export class AccordionItemComponent implements OnInit {
  @Input() isSideBarOpen: boolean;
  @Input() isThisAccordionOpened: boolean;
  @Input() workspace: Workspace;
  @Output() toggleAccordionEvent = new EventEmitter();
  @Input() active: string = "design";
  @Input() isFeatured = false;

  toggleAccordion(): void {
    this.toggleAccordionEvent.emit();
  }

  constructor() { }

  ngOnInit() {
  }
}
