import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/auth/auth.guard';
import { PlayResolver } from 'src/app/core/models/resolvers/play.resolver';
import { PrivilegeGuard } from '../core/privilege/privilege.guard';
import { PRIVILEGES } from '../core/privilege/privilege-roles';
import { ScriptResolver } from '../script/script.resolver';

const routes: Routes = [
  {
    path: 'organize',
    loadChildren: () => import('./list/play-list-page.module').then(m => m.PlayListPageModule),
    canActivate: [AuthGuard, PrivilegeGuard],
    data: {privilege: PRIVILEGES.ORGANIZE_PAGE_VIEW, redirectTo: 'play-landing'}
  },
  {
    path: 'organize/:playID/edit',
    loadChildren: () => import('./edit/play-edit-page.module').then(m => m.PlayEditPageModule),
    canActivate: [AuthGuard, PrivilegeGuard],
    data: {privilege: PRIVILEGES.ORGANIZE_PAGE_VIEW, redirectTo: 'play-landing'},
    resolve: { play: PlayResolver }
  },
  {
    path: 'organize/create/:scriptID',
    loadChildren: () => import('./edit/play-edit-page.module').then(m => m.PlayEditPageModule),
    canActivate: [AuthGuard, PrivilegeGuard],
    data: {privilege: PRIVILEGES.ORGANIZE_PAGE_VIEW, redirectTo: 'play-landing'},
    resolve: { script: ScriptResolver }
  },
  {
    path: 'organize/:playID',
    loadChildren: () => import('./view/play-page-view.module').then(m => m.PlayViewPageModule),
    canActivate: [AuthGuard, PrivilegeGuard],
    data: {privilege: PRIVILEGES.ORGANIZE_PAGE_VIEW, redirectTo: 'play-landing'},
    resolve: { play: PlayResolver }
  },
  {
    path: 'organize/:playID/live',
    loadChildren: () => import('./monitoring/monitoring.module').then(m => m.MonitoringModule),
    canActivate: [AuthGuard, PrivilegeGuard],
    data: {privilege: PRIVILEGES.ORGANIZE_PAGE_VIEW, redirectTo: 'play-landing'},
    resolve: { play: PlayResolver }
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  providers: [
    PlayResolver
  ]
})
export class PlayRoutingModule { }
