<app-new-team-sidebar></app-new-team-sidebar>
<ion-content>
  <app-profile-menu></app-profile-menu>
  <content-container class="py-5"
                     [back-button]="false"
                     [pageTitle]="'user-profile/page-title' | translate">

    <form #profileForm="ngForm">
      <div class="user-details"
           *ngIf="authService.authenticated">

        <div class="detail-wrapper">
          <div class="detail-label">{{ 'user-profile/email-label' | translate }}</div>
          <div>{{ user?.email }}</div>
        </div>

        <div class="detail-wrapper">
          <div class="detail-label">{{ 'user-profile/full-name' | translate }}</div>
          <div *ngIf="!displayNameIsEditing"
               class="display-name-container">
            <div>{{ user?.displayName }}</div>
            <mat-icon (click)="displayNameIsEditing = true; analytics.createFullNameEditClickedEvent()"
                      class="edit-icon">create</mat-icon>
          </div>

          <div *ngIf="displayNameIsEditing"
               class="display-name-container">
            <div class="display-name-editor">
              <div class="input-container">
                <input type="text"
                       #displayNameField="ngModel"
                       name="displayName"
                       (focusin)="analytics.createFullNameFieldClickedEvent()"
                       [(ngModel)]="user.displayName"
                       required>
                <div *ngIf="displayNameField.touched && displayNameField.invalid"
                     class="alert invalid-field">
                  {{'user-profile/full-name-required' | translate}}
                </div>
              </div>

              <div class="display-name-action">
                <ion-button shape="round"
                            type="submit"
                            color="secondary"
                            (click)="setDisplayName()"
                            [disabled]="displayNameField.invalid">
                  {{'general/ok' | translate}}
                </ion-button>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="user" class="form-group mt-2 w-30">
          <div class="row">
            <div class="col-4">
              <label for="displayName">{{ 'playing/profile/language/label' | translate }}</label>
              <select [(ngModel)]="user.language" name="language" class="form-control" (change)="changeLanguage($event)">
                <ng-container *ngFor="let languageItem of languages">
                  <option [value]="languageItem.code">{{ 'playing/profile/language/option/' + languageItem.language | translate }}</option>
                </ng-container>
              </select>
            </div>
          </div>

        </div>

        <div class="detail-wrapper">
          <div class="detail-label">{{ 'user-profile/profile-picture' | translate }}</div>
          <div class="profile-wrapper"
               [class.cropperReady]="cropperReady"
               (mouseover)="setProfilePictureUploadIconVisibility(true)"
               (mouseleave)="setProfilePictureUploadIconVisibility(false)"
               [ngClass]="{'profile-picture-image-faded': this.isUploading()}">
            <div class="profile-picture-upload-button-wrapper"
                 [hidden]="!profilePictureIconVisible">
              <mat-icon (click)="openSelectDialog(); analytics.createProfilePictureUploadClickedEvent()"
                        class="profile-picture-upload-button">camera_alt</mat-icon>

              <input #fileInput type="file" class="profile-picture-upload-input" capture="camera" accept="image/jpeg,image/x-png" (change)="fileSelected($event)"/>
            </div>
            <div class="progress profile-picture-upload-progress-wrapper"
                 [hidden]="!isUploading()">
              <ion-spinner name="lines"></ion-spinner>
            </div>
            <image-cropper
            class="profile-picture-image"
            *ngIf="imageChangedEvent || imageBase64"
            [imageChangedEvent]="imageChangedEvent"
            [imageBase64]="imageBase64"
            [resizeToWidth]="640"
            format="jpg"
            (imageCropped)="imageCropped($event)"
            (imageLoaded)="cropperReady = true"
            (cropperReady)="cropperReady = true"
          ></image-cropper>
            <img *ngIf="user && !cropperReady && user?.photoURL" [src]="user?.photoURL" alt="avatar" [hidden]="isUploading()"
            class="img-fluid profile-picture-image" />

          </div>

          <div *ngIf="cropperReady" class="display-name-action ml-2">
            <ion-button shape="round"
                        type="submit"
                        color="secondary"
                        (click)="saveImage()"
                        [disabled]="processing">
              <ion-spinner *ngIf="processing === 'profile-image'" name="lines-small"></ion-spinner>          
              {{'general/save' | translate}}
            </ion-button>
          </div>
        </div>
        <div class="detail-wrapper text-right">
          <a class="remove-btn" (click)="removeProfile()">{{ 'user-profile/delete-account' | translate }}</a>
        </div>

      </div>
    </form>
  </content-container>
</ion-content>
