import { Component, OnInit } from '@angular/core';
import { Organization } from 'src/app/core/models/organization.model';
import { ModelProvider } from 'src/app/core/models/general/model.provider';
import { Router } from '@angular/router';
import { of, Subscription } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { switchMap } from 'rxjs/operators';

const PAGE_SIZE = 10;

@Component({
  selector: 'app-superadmin-organization-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class SuperadminOrganizationListComponent implements OnInit {
  organizations: Organization[] = [];
  subscriptions: Subscription[] = [];
  lastVisible: any = null;
  loadingMore: boolean = false;
  isDataFetching: boolean = false;
  searchString: string;

  constructor(
    private router: Router,
    private firestore: AngularFirestore
  ) { }

  ngOnInit() {
    this.isDataFetching = true;
    this.loadInitialOrganizations();
  }

  private loadInitialOrganizations() {
    const totalSub = this.firestore.collection(`organizations`, ref => {
      return ref.orderBy('createdAt', 'desc').limit(PAGE_SIZE);
    }).snapshotChanges().pipe(
      switchMap(snapshot => {
        if (snapshot.length > 0) {
          this.lastVisible = snapshot[snapshot.length - 1].payload.doc;
          return this.processOrganization(snapshot);
        }
        return of([]);
      }),
    ).subscribe(organizations => {
      this.organizations = organizations;
      this.isDataFetching = false;
    }, error => {
      this.isDataFetching = false;
      console.error('Error fetching records:', error);
    });

    this.subscriptions.push(totalSub);
  }
  private loadSearchedOrganizations() {
    this.isDataFetching = true;
    const endString = this.searchString.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));
  
    const totalSub = this.firestore.collection('organizations', ref => {
      return ref
        .orderBy('title')
        .startAt(this.searchString)
        .endAt(endString)
        .limit(PAGE_SIZE);
    }).snapshotChanges().pipe(
      switchMap(snapshot => {
        if (snapshot.length > 0) {
          this.lastVisible = snapshot[snapshot.length - 1].payload.doc;
          return this.processOrganization(snapshot);
        }
        return of([]);
      })
    ).subscribe(organizations => {
      this.organizations = organizations;
      this.isDataFetching = false;
    }, error => {
      this.isDataFetching = false;
      console.error('Error fetching filtered records:', error);
    });
  
    this.subscriptions.push(totalSub);
  }

  private processOrganization(snapshot) {
    const organizations = snapshot.map(doc => {
      const org = doc.payload.doc.data() as Organization;
      return {
        ...org,
        id: doc.payload.doc.id,
        plan: {
          status: org.plan?.status,
          title: org.plan?.ref?.id
        }
      };
    });
    return of(organizations);
  }

  loadMore(event) {
    if (this.loadingMore || !this.lastVisible) return;
    this.loadingMore = true;
  
    if (this.searchString && this.searchString.trim().length > 0) {
      const endString = this.searchString.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));
  
      const moreSub = this.firestore.collection('organizations', ref => {
        return ref
          .orderBy('title')
          .startAt(this.searchString)
          .endAt(endString)
          .startAfter(this.lastVisible) 
          .limit(PAGE_SIZE);
      }).snapshotChanges().pipe(
        switchMap(snapshot => {
          if (snapshot.length > 0) {
            this.lastVisible = snapshot[snapshot.length - 1].payload.doc;
            return this.processOrganization(snapshot);
          }
          event.target.complete(); 
          this.loadingMore = false;
          return of([]);
        }),
      ).subscribe(newOrganizations => {
        this.organizations = this.organizations.concat(newOrganizations);
        event.target.complete();
        this.loadingMore = false;
      }, error => {
        console.error('Error loading more filtered records:', error);
        event.target.complete();
        this.loadingMore = false;
      });
  
      this.subscriptions.push(moreSub);
    } else {
      const moreSub = this.firestore.collection('organizations', ref => {
        return ref
          .orderBy('createdAt', 'desc')
          .startAfter(this.lastVisible)
          .limit(PAGE_SIZE);
      }).snapshotChanges().pipe(
        switchMap(snapshot => {
          if (snapshot.length > 0) {
            this.lastVisible = snapshot[snapshot.length - 1].payload.doc;
            return this.processOrganization(snapshot);
          }
          event.target.complete();
          this.loadingMore = false;
          return of([]);
        }),
      ).subscribe(newOrganizations => {
        this.organizations = this.organizations.concat(newOrganizations);
        event.target.complete();
        this.loadingMore = false;
      }, error => {
        console.error('Error loading more records:', error);
        event.target.complete();
        this.loadingMore = false;
      });
  
      this.subscriptions.push(moreSub);
    }
  }  

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  handleSearch(event) {
    this.searchString = event.target.value.trim();
    this.subscriptions.forEach(sub => sub.unsubscribe());
    if (this.searchString.length > 0) {
      this.loadSearchedOrganizations();
    } else {
      this.loadInitialOrganizations();
    }
  }

  async editOrganization(organization: Organization) {
    await this.router.navigate(['superadmin', 'organization', 'edit', organization.id])
  }

  async settingsOrganization(organization: Organization) {
    await this.router.navigate(['organization', organization.id])
  }

  async createTeam() {
    await this.router.navigate(['superadmin', 'organization', 'create'])
  }

}
