<app-new-team-sidebar></app-new-team-sidebar>
<ion-content [scrollEvents]="true" (ionScroll)="onWindowScroll($event)">
  <app-profile-menu></app-profile-menu>
  <content-container *ngIf="!sent" [back-button]="plan" #contentContainer>
    <div class="row">
      <div *ngIf="plan" class="col-7">
        <h2>Create new {{plan.title}} plan account</h2>
        <form #form="ngForm" class="form">
          <div class="form-group">
            <label for="organizationName">Team name</label>
            <input [(ngModel)]="organizationName" name="organizationName" type="text" class="col-sm-8 px-0" />
          </div>
          <div class="form-group">
            <label for="organizationName">Choose billing type</label>
            <ion-radio-group class="choice" (ionChange)="onSelectBillingType($event)">
              <ion-item *ngIf="yearly" class="p-0 m-0 py-2" mode="md" lines="none">
                <ion-label class="m-0 p-0 ion-text-wrap">
                  <div class="row">
                    <div class="col-6">Yearly <ion-badge *ngIf="yearly && monthly" class="ml-2 text-uppercase">
                        Save
                        {{(1 - (yearly.currencies[currency]?.amount / 12 / monthly.currencies[currency].amount)) * 100 | number:'1.0-0'}}%
                      </ion-badge>
                    </div>
                    <div class="col-6">{{yearly.currencies[currency]?.amount / 12 | currency: (currency | uppercase)}} /
                      month</div>
                  </div>
                </ion-label>
                <ion-radio [value]="'yearly'" [checked]="billingType === 'yearly'" slot="start" color="dark"
                  class="m-0 mr-3 p-0" slot="start">
                </ion-radio>
              </ion-item>
              <ion-item *ngIf="monthly" class="p-0 m-0 py-2" mode="md" lines="none">
                <ion-label class="m-0 p-0 ion-text-wrap">
                  <div class="row">
                    <div class="col-6">Monthly</div>
                    <div class="col-6">{{monthly.currencies[currency]?.amount | currency: (currency | uppercase)}} /
                      month</div>
                  </div>
                </ion-label>
                <ion-radio [disabled]="paymentMethod === 'invoice'" [value]="'monthly'"
                  [checked]="billingType === 'monthly'" slot="start" color="dark" class="m-0 mr-3 p-0" slot="start">
                </ion-radio>
              </ion-item>
            </ion-radio-group>
          </div>
          <div class="form-group">
            <label for="organizationName">Choose payment method</label>
            <ion-radio-group class="choice" [value]="paymentMethod" (ionChange)="onSelectPaymentMethod($event)">
              <ion-item class="p-0 m-0 py-2" mode="md" lines="none">
                <ion-label class="m-0 p-0 ion-text-wrap">
                  Credit card
                  <ion-icon name="help-circle" [popper]="cardInfoTextPopper" [popperTrigger]="'hover'"
                  [popperPlacement]="'right'" popperAppendTo="body" [popperApplyClass]="'info-text-popper'">
                </ion-icon>
                <popper-content popperApplyClass="popover-color" #cardInfoTextPopper>
                  <ul class="m-0 p-0 pl-4">
                    <li>Monthly and Yearly payment options</li>
                    <li>Credit card is charged and the Gamoteca Pro account is activated immediately</li>
                    <li>Recurring payments (monthly/yearly) are charged automatically during renewal</li>
                  </ul>
                </popper-content>
                </ion-label>
                <ion-radio [value]="'card'" [checked]="paymentMethod === 'card'" slot="start" color="dark"
                  class="m-0 mr-3 p-0" slot="start">
                </ion-radio>
              </ion-item>
              <ion-item class="p-0 m-0 py-2" mode="md" lines="none">
                <ion-label class="m-0 p-0 ion-text-wrap">
                  Invoice
                  <ion-icon name="help-circle" [popper]="invoiceInfoTextPopper" [popperTrigger]="'hover'"
                    [popperPlacement]="'right'" popperAppendTo="body" [popperApplyClass]="'info-text-popper'">
                  </ion-icon>
                  <popper-content popperApplyClass="popover-color" #invoiceInfoTextPopper>
                    <ul class="m-0 p-0 pl-4">
                      <li>Only Yearly payment option</li>
                      <li>Invoice is generated, with a 15 day period to make payment via credit card</li>
                      <li>Invoice can be shared with someone else for payment, e.g. Finance or corporate card holder</li>
                      <li>Account is activated immediately, but the account is deactivated if payment is not received within 15 days</li>
                      <li>Invoices are generated automatically for yearly renewal, with 15 days payment period</li>
                    </ul>
                  </popper-content>
                </ion-label>
                <ion-radio [value]="'invoice'" [checked]="paymentMethod === 'invoice'" slot="start" color="dark"
                  class="m-0 mr-3 p-0" slot="start">
                </ion-radio>
              </ion-item>
            </ion-radio-group>
          </div>
          <div *ngIf="paymentMethod === 'card'" class="form-group">
            <label>Credit card details</label>
            <ngx-stripe-card [options]="cardOptions" [elementsOptions]="elementsOptions"></ngx-stripe-card>
          </div>
          <h3 class="mt-5">Billing Information</h3>
          <div class="form-group">
            <label for="billingName">Company name</label>
            <input [(ngModel)]="billingName" name="billingName" required id="billingName" type="text" />
          </div>
          <div class="form-group">
            <label for="address">Address</label>
            <input [(ngModel)]="address" name="address" required id="address" type="text" />
          </div>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label for="country">Country</label>
                <select [(ngModel)]="country" (ngModelChange)="calculateTotal()" name="country" required name="country"
                  class="form-control">
                  <ng-container *ngFor="let countryItem of countries | keyvalue | orderBy: 'value.label'">
                    <ng-container *ngIf="countryItem.key; let localeCode">
                      <ng-container *ngIf="countryItem.value; let country">
                        <option [value]="localeCode">{{country.label}}</option>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </select>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label for="city">City</label>
                <input [(ngModel)]="city" name="city" required id="city" type="text" />
              </div>
            </div>
          </div>
          <div *ngIf="countries[country].isEU" class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <label for="taxID">{{countries[country].vatLabel}}</label>
                <input type="text" [(ngModel)]="taxID" [prefix]="countries[country].vatPrefix"
                [dropSpecialCharacters]="false" [suffix]="(countries[country]?.vatSuffix)?countries[country]?.vatSuffix:''"
                  (ngModelChange)="calculateTotal()" [mask]="countries[country].vatMask" name="taxID" id="taxID" [attr.required]="countries[country].isEU && country !== sourceCountry" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label for="state">State/Province</label>
                <input [(ngModel)]="state" name="state" required id="state" type="text" />
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label for="zip">Postal code</label>
                <input [(ngModel)]="zip" name="zip" required id="zip" type="text" />
              </div>
            </div>
          </div>
          <div class="form-group py-5 mb-0" [class.sticky-bottom]="sticky">
            <div class="form-check mb-4">
              <input [(ngModel)]="terms" name="term" class="form-check-input" type="checkbox" id="terms" required>
              <label class="form-check-label" for="terms">
                I agree to the <a href="https://www.gamoteca.com/terms-and-conditions/" target="_blank">Terms and Conditions</a> and the <a href="https://www.gamoteca.com/privacy-policies/" target="_blank">Privacy Policy</a>
              </label>
            </div>
            <ion-button (click)="save()" type="submit" [disabled]="!form.form.valid || processing" color="primary"
              shape="round" class="float-left">
              <ion-spinner *ngIf="processing" name="lines-small"></ion-spinner>
              Buy {{plan.title}}
            </ion-button>
            <div class="price-summary float-left ml-4">
              <div class="total">Total: {{total | currency: (currency | uppercase)}}</div>
              <div class="details">
                {{this.price.currencies[this.currency].amount | currency: (currency | uppercase)}} +
                {{vatPercent}}% VAT</div>
            </div>
            <div class="clearfix"></div>
          </div>
          <div></div>
        </form>
      </div>
      <div class="col-5">
        <div class="what-get">

          <h2 class="mt-2">What will you get</h2>
          <ul *ngIf="plan">
            <li>
              <ion-icon name="checkmark"></ion-icon>
              <span>Collaborate with
                <ng-container *ngIf="plan.features.designerLimitPerWorkspace < 0">unlimited</ng-container>
                <ng-container *ngIf="plan.features.designerLimitPerWorkspace >= 0">
                  {{plan.features.designerLimitPerWorkspace * plan.features.workspaceLimit}}</ng-container>
                game creators
                <ng-container *ngIf="plan.features.designerLimitPerWorkspace >= 0">
                  <br />({{plan.features.designerLimitPerWorkspace}} creators per workspace)
                </ng-container>
              </span>
            </li>
            <li>
              <ion-icon name="checkmark"></ion-icon>
              <span>
                <ng-container *ngIf="plan.features.workspaceLimit < 0">Unlimited</ng-container>
                <ng-container *ngIf="plan.features.workspaceLimit >= 0">{{plan.features.workspaceLimit}}</ng-container>
                Team Workspaces
              </span>
            </li>
            <li>
              <ion-icon name="checkmark"></ion-icon>
              <span>
                <ng-container *ngIf="plan.features.scriptPerWorkspaceLimit < 0">Unlimited games</ng-container>
                <ng-container *ngIf="plan.features.scriptPerWorkspaceLimit >= 0">
                  Create up to
                  {{plan.features.scriptPerWorkspaceLimit * plan.features.workspaceLimit}}
                  games
                  <br />({{plan.features.scriptPerWorkspaceLimit}} games per workspace)
                </ng-container>
              </span>
            </li>
            <li>
              <ion-icon name="checkmark"></ion-icon>
              <span>
                <ng-container *ngIf="plan.features.playerLimit < 0">Unlimited</ng-container>
                <ng-container *ngIf="plan.features.playerLimit >= 0">Invite up to {{plan.features.playerLimit}}
                </ng-container>
                learners per workspace
              </span>
            </li>
            <li>
              <ion-icon name="checkmark"></ion-icon>
              <span>Advanced game analytics </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </content-container>
  <content-container *ngIf="sent && plan" [back-button]="'organization/create'">
    <h2 *ngIf="paymentMethod === 'invoice'">Thank you for upgrading to Gamoteca {{plan.title}}!</h2>
    <h2 *ngIf="paymentMethod === 'card'">Congratulations on upgrading to Gamoteca {{plan.title}}!</h2>

    <p *ngIf="paymentMethod === 'invoice' && invoiceLinkURL && invoiceLinkHtml">Your upgrade will be completed once the
      invoice is processed: <br />
      <a [href]="invoiceLinkURL" target="_blank" [innerHtml]="invoiceLinkHtml"></a>
    </p>

    <p>
      Click here to access your Gamoteca {{plan.title}} Team account and billing page:<br />
    </p>
    <ion-button (click)="goToOrganizationAccount()" shape="round">Team account</ion-button>

    <p class="mt-5">
      In addition to Gamoteca’s free features, with Gamoteca Pro you will have access to:
    </p>

    <div class="what-get success p-0">
      <ul *ngIf="plan">
        <li>
          <ion-icon name="checkmark"></ion-icon>
          <span>Collaborate with
            <ng-container *ngIf="plan.features.designerLimitPerWorkspace < 0">unlimited</ng-container>
            <ng-container *ngIf="plan.features.designerLimitPerWorkspace >= 0">
              {{plan.features.designerLimitPerWorkspace * plan.features.workspaceLimit}}</ng-container>
            game creators 
            <ng-container *ngIf="plan.features.designerLimitPerWorkspace >= 0">
              ({{plan.features.designerLimitPerWorkspace}} creators per workspace)
            </ng-container>
          </span>
        </li>
        <li>
          <ion-icon name="checkmark"></ion-icon>
          <span>
            <ng-container *ngIf="plan.features.workspaceLimit < 0">Unlimited</ng-container>
            <ng-container *ngIf="plan.features.workspaceLimit >= 0">{{plan.features.workspaceLimit}}</ng-container>
            Team Workspaces
          </span>
        </li>
        <li>
          <ion-icon name="checkmark"></ion-icon>
          <span>
            <ng-container *ngIf="plan.features.scriptPerWorkspaceLimit < 0">Unlimited games</ng-container>
            <ng-container *ngIf="plan.features.scriptPerWorkspaceLimit >= 0">
              Create up to
              {{plan.features.scriptPerWorkspaceLimit * plan.features.workspaceLimit}}
              games
              ({{plan.features.scriptPerWorkspaceLimit}} games per workspace)
            </ng-container>
          </span>
        </li>
        <li>
          <ion-icon name="checkmark"></ion-icon>
          <span>
            <ng-container *ngIf="plan.features.playerLimit < 0">Unlimited</ng-container>
            <ng-container *ngIf="plan.features.playerLimit >= 0">Invite up to {{plan.features.playerLimit}}
            </ng-container>
            learners per workspace
          </span>
        </li>
        <li>
          <ion-icon name="checkmark"></ion-icon>
          <span>Advanced game analytics </span>
        </li>
      </ul>
    </div>

    <p class="mt-5">
      To get started with Gamoteca {{plan.title}}, make sure to check out these resources: <br />
      <a href="https://www.gamoteca.com/how-does-it-work#support" target="_blank">Help articles and How to videos</a>
    </p>

    <p>Contact us any time via the chat window on any screen if you have questions. If you just need some
      inspiration, <br />explore our
      <a href="https://www.gamoteca.com/blog/" target="_blank">blog</a> for best practices.
    </p>

    <p *ngIf="paymentMethod === 'card'">Enjoy creating engaging game-based learning, now with your team!</p>

  </content-container>
</ion-content>