<div *ngIf="workspaceService.hasDesignerWorkspace$() | async" [ngClass]="{ 'closed': !isSideBarOpen }">
  <ng-container *ngIf="organizations && organizations?.length > 0">
    <div *ngIf="organizationService.activeOrganization$ | async; let activeOrganization" class="desktop-trigger">
      <div class="nav-company-tile" [ngClass]="{'closed': !isSideBarOpen}"
        [context-menu]="organizationContextMenu"
        [popperPositionFixed]="true"
        [popperModifiers]="{offset: {offset: '-5px,5px'}, flip: {enabled: false}}"
        [popperApplyClass]="'menu-popper'"
        [popperBoundaries]="'body'"
        [popperAppendTo]="'body'"
      >
        <div id="nav-compnay-tile-header">
          <img id="nav-compnay-tile-logo" [src]="activeOrganization?._image | size : 'xs' : 'xs' : activeOrganization?.photoURL || '../../../assets/images/landing-page-img.jpg'" alt="Organization Logo"/>
          <div id="nav-compnay-tile-info">
            <div id="nav-compnay-tile-title" *ngIf="isSideBarOpen">{{activeOrganization.title}}</div>
            <div id="nav-compnay-tile-plan" *ngIf="isSideBarOpen">{{activeOrganization.plan.ref.id | titlecase}} plan</div>
          </div>
        </div>
        <div [routerLink]="['/organization', activeOrganization.id]" (click)="$event.stopPropagation()">
          <mat-icon slot="icon-only">settings</mat-icon>
        </div>
      </div>
      <context-group #organizationContextMenu>
        <context-item *ngFor="let organization of organizations; let organizationIndex = index"
        [label]="organization.title"
        (click)="setOrganization(organization)"
        [class.highlighted]="activeOrganization.ref.path === organization.ref.path"
        [class.divider]="organizations.length > 2 && organizationIndex === 1"
        ></context-item>
      </context-group>
    </div>
  </ng-container>
</div>