import { Component, Input, OnInit } from '@angular/core';
import { WorkspaceService } from '../workspace.service';
import { Organization } from '../../models/organization.model';
import { User } from '../../models/user.model';
import { AuthService } from 'src/app/auth/auth.service';
import { ModelProvider } from '../../models/general/model.provider';
import { OrganizationService } from 'src/app/organization/organization.service';
import { LoadingController, ModalController } from '@ionic/angular';
import { first } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { HeaderAnalyticsService } from '../../header/header.analytics';

@Component({
  selector: 'app-company-tile',
  templateUrl: './company-tile.component.html',
  styleUrls: ['./company-tile.component.scss'],
})
export class CompanyTileComponent implements OnInit {
  @Input() isSideBarOpen: boolean;
  user: User;

  organizations: Organization[];

  private subscriptions: Subscription[] = [];  
  private _organization: Organization;

  constructor(private router: Router,
    public authService: AuthService,
    public analytics: HeaderAnalyticsService,
    public modalController: ModalController,
    public workspaceService: WorkspaceService,
    public organizationService: OrganizationService,
    public modelProvider: ModelProvider,
    public loadingCtrl: LoadingController,
  ) {}

  async ngOnInit() {
    this.user = await this.authService.user$.pipe(first()).toPromise();
    const orgSub = this.organizationService.organizations$.subscribe(organizations => {
      this.organizations = organizations;
    });
    this.subscriptions.push(orgSub);
  }

  public async setOrganization(organization: Organization) {
    if (this._organization && this._organization.ref.path === organization.ref.path) { return; }

    const loading = await this.loadingCtrl.create({
      message: 'Changing team...',
    });
    await loading.present();
    try {
      this._organization = organization;

      const activeOrganization = organization;
      const workspaces = this.workspaceService.workspaces;
      let filteredWorkspaces = workspaces.filter(workspace => {
        if (workspace.ownerOrganizationRef) {
          return workspace.ownerOrganizationRef.isEqual(activeOrganization.ref);
        } else {
          return activeOrganization.isOrphan();
        }
      });

      filteredWorkspaces = await this.modelProvider.asyncFilter(filteredWorkspaces, async (workspace) =>
        (await workspace.getMyMember()).level !== 'player'
      );

      filteredWorkspaces
        .sort((a, b) => {
          if (a.isPersonal && !b.isPersonal) {
            return -1;
          } else if (!a.isPersonal && b.isPersonal) {
            return 1;
          }

          if (a.myMember.joinedAt && !b.myMember.joinedAt) {
            return -1;
          } else if (!a.myMember.joinedAt && b.myMember.joinedAt) {
            return 1;
          } else if (a.myMember.joinedAt && b.myMember.joinedAt) {
            return a.myMember.joinedAt.toMillis() > b.myMember.joinedAt.toMillis() ? 1 : -1;
          }

          return 0;
        });

      if (filteredWorkspaces.length > 0) {
        this.workspaceService.setActiveWorkspace(filteredWorkspaces[0]);
      }
      await this.organizationService.setOrganization(organization);
      this.router.navigateByUrl('/design');

      return true;
    } catch (error) {
      throw error;
    } finally {
      await loading.dismiss();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
