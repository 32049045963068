import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { environment } from '../environments/environment';
import { ScriptResolver } from './script/script.resolver';

const routes: Routes = [
  { path: '', redirectTo: environment.homeURL, pathMatch: 'full' },
  { path: 'welcome',      loadChildren: () => import('./welcome/welcome.module').then(m => m.WelcomePageModule) },
  { path: 'auth/org-not-found', loadChildren: () => import('./organization/organization-not-found/organization-not-found.module').then(m => m.OrganizationNotFoundModule) },
  { path: 'auth/login',   loadChildren: () => import('./auth/login/login.module').then(m => m.LoginPageModule) },
  { path: 'auth/sign-up', loadChildren: () => import('./auth/sign-up/sign-up.module').then(m => m.SignUpPageModule) },
  {
    path: 'auth/verification-required',
    loadChildren: () => import('./auth/verification-required/verification-required.module').then(m => m.VerificationRequiredPageModule)
  },
  {
    path: 'analytics',
    loadChildren: () => import('./analytics/analytics.module').then(m => m.AnalyticsPageModule)
  },
  {
    path: 'analytics/:scriptID/reporting',
    loadChildren: () => import('./reporting/script-report/script-report.module').then(m => m.ScriptReportPageModule),
    resolve: { script: ScriptResolver }
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
