import { LayoutItem } from './task-layout-item';
import { Task } from './task.model';

export class VideoLayoutItem extends LayoutItem {
    public type: string = LayoutItem.TYPE_VIDEO;
    public order = 20;

    public properties: {
        isTranscoding?: boolean,
        sizes?: {
            original: {
                ref?: string,
                url: string,
                mimeType: string,
                size: string,
            },
            md?: {
                ref: string,
                url: string,
                mimeType: string,
                size: string,
            }
        },
    };

    public get mediaType() {
        if (this.properties && this.properties.sizes && this.properties.sizes.original && this.properties.sizes.original.url) {
            if (this.properties.sizes.original.url.startsWith('https://www.youtube.com')) {
                return 'youtube'
            }
        }

        return 'mp4';
    }

    public getYoutubeID(url?: string) {
        url = url || this.properties.sizes.original.url;

        var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        var match = url.match(regExp);
        return (match && match[7].length == 11) ? match[7] : null;
    }

    public get url() {
        const sizes = this.modelProvider.platform.is('desktop') ? ['800x', '576x', 'original'] : ['576x', '800x', 'original'];
        for (const size of sizes) {
            if (this.properties && this.properties.sizes && this.properties.sizes[size] && this.properties.sizes[size].url) {
                return this.properties.sizes[size].url;
            }
        }

        return undefined;
    }

    public get isTranscoding() {
        if (this.properties.isTranscoding) {
            return true;
        }

        const sizes = ['800x', '576x', 'original'];
        for (const size of sizes) {
            if (this.properties && this.properties.sizes && this.properties.sizes[size] && this.properties.sizes[size].transcoding) {
                return true;
            }
        }

        return false;
    }

    public setVideo(url: string, ref: string = null, mimeType: string, size = 'original') {
        if (!this.properties) { this.properties = {}; }
        this.properties = {
            ...this.properties,
            sizes: {
                original: {
                    url: url,
                    ref: ref,
                    mimeType: mimeType,
                    size: size
                }
            }
        }
    }

    public uploadFile(category: string, file: File, metadata?: any) {
        const path = (this.documentInstance as Task).getDocument().ref.path + '/' + this.type + '/' + file.name;
        const reference = this.modelProvider.fsStorage.ref(path);
        this.properties.isTranscoding = true;
        return { ref: reference, uploadTask: reference.put(file, { customMetadata: metadata || {} }) };
    }

    public get task() {
        return this.documentInstance as Task
    }

    private isLocalFile() {
        return (this.properties && this.properties.sizes && this.properties.sizes.original && this.properties.sizes.original.url.startsWith('blob:'));
    }

    public isTranscoded() {
        return !!!(this.properties && this.properties.sizes && this.properties.sizes.md && this.properties.sizes.md.url);
    }
}
