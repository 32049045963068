<div class="profile-menu">
  <div
    *ngIf="user"
    class="profile-menu-row"
    [context-menu]="profileContextMenu2"
    (click)="toggleContextMenu()"
  >
    <p>{{ user.displayName }}</p>
    <img [src]="user.photoURL" />
    <context-group #profileContextMenu2>
      <context-item
        label="{{ 'user-profile/context-menu/profile' | translate }}"
        (click)="openProfile()"
        [class.divider]="true"
      >
      </context-item>
      <context-item
        label="{{ 'user-profile/context-menu/help' | translate }}"
        (click)="openHelpCenter()"
        [class.divider]="true"
      >
      </context-item>
      <context-item
        label="{{ 'user-profile/context-menu/logout' | translate }}"
        (click)="logout()"
        [class.divider]="true"
      >
      </context-item>
    </context-group>
  </div>
</div>
