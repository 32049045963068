import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { User } from '../../models/user.model';
import { AuthService } from '../../../auth/auth.service';
import { WorkspaceService } from '../workspace.service';
import { Router } from '@angular/router';
import { Workspace } from '../../models/workspace.model';
import { map, first, mergeMap } from 'rxjs/operators';
import { Subscription, combineLatest } from 'rxjs';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { ModalController } from '@ionic/angular';
import { OrganizationService } from 'src/app/organization/organization.service';
import { ModelProvider } from '../../models/general/model.provider';
import { SidebarService } from '../sidebar.service';

@Component({
    selector: 'app-new-team-sidebar',
    templateUrl: './new-team-sidebar.component.html',
    styleUrls: ['./new-team-sidebar.component.scss'],
})
export class NewTeamSidebarComponent implements OnInit, OnDestroy {
    @Input() active: string = "design";
    @Input() navigateOnChange: Array<any>;
    user: User;
    isSideBarOpen = true;
    workspaces: Workspace[];
    featuredWorkspaces: Workspace[] = [];

    public processing = false;
    public subscriptions: Subscription[] = [];

    constructor(
        private auth: AuthService,
        private router: Router,
        private modelProvider: ModelProvider,
        public workspaceService: WorkspaceService,
        public organizationService: OrganizationService,
        private sanitizer: DomSanitizer,
        private modalController: ModalController,
        private sidebarService: SidebarService,
        public authService: AuthService,
    ) {
        this.sidebarService.sidebarOpen$.subscribe(isOpen => {
            this.isSideBarOpen = isOpen;
        });
    }

    async ngOnInit() {
        this.subscriptions.push(
            this.auth.user$.subscribe(user => this.user = user)
        );

        const templateWorkspace = await this.modelProvider.workspace.findByID('demo-games').pipe(first()).toPromise();
        this.featuredWorkspaces.push(templateWorkspace);

        const workspacesSubscription$ = this.workspaceService.workspaces$
            .pipe(mergeMap(async workspaces => {
                return await this.workspaceService.modelProvider.asyncFilter(workspaces, async workspace =>
                    (await workspace.getMyMember()).level !== 'player'
                );
            }))
            .pipe(
                map(workspaces => workspaces.sort((a, b) => {
                    if (a.isPersonal && !b.isPersonal) {
                        return -1;
                    } else if (!a.isPersonal && b.isPersonal) {
                        return 1;
                    }
                    if (a.myMember.joinedAt && !b.myMember.joinedAt) {
                        return -1;
                    } else if (!a.myMember.joinedAt && b.myMember.joinedAt) {
                        return 1;
                    } else if (a.myMember.joinedAt && b.myMember.joinedAt) {
                        return a.myMember.joinedAt.toMillis() > b.myMember.joinedAt.toMillis() ? 1 : -1;
                    }

                    return 0;
                }))
            )
        const organizationFilteredWorkspaces$ = combineLatest(
            workspacesSubscription$,
            this.organizationService.activeOrganization$
        )
            .pipe(
                map(([workspaces, activeOrganization]) => {
                    return workspaces.filter(workspace => {
                        if (workspace.ownerOrganizationRef) {
                            return workspace.ownerOrganizationRef.isEqual(activeOrganization.ref);
                        } else {
                            return activeOrganization.isOrphan();
                        }
                    });
                })
            )
            .subscribe(workspaces => this.workspaces = workspaces);
        this.subscriptions.push(
            organizationFilteredWorkspaces$
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    async setActiveWorkspace(workspace: Workspace) {
        this.workspaceService.setActiveWorkspace(workspace);

        if (this.navigateOnChange) {
            const activeWorkspace = await this.workspaceService.activatedWorkspace.pipe(first()).toPromise();
            if (!workspace.ref.isEqual(activeWorkspace.ref)) {
                this.router.navigate(this.navigateOnChange);
            }
        }
    }

    async addNewWorkspace() {
        const organization = await this.organizationService.activeOrganization$.pipe(first()).toPromise();

        const organizationMember = await organization.myMember$.pipe(first()).toPromise();

        if (!['owner', 'admin'].includes(organizationMember.level)) {
            return;
        }

        const isValidWorkspaceLimit = await organization.isValidWorkspaceLimit();
        if (!isValidWorkspaceLimit) {
            await this.organizationService.showWorkspaceLimitWarning();
            return;
        }

        if (this.processing) { return; }
        this.processing = true;
        const workspace = await this.workspaceService.addNew(organization);

        if (workspace) {
            const workspaces = await this.workspaceService.workspaces$.pipe(first()).toPromise();
            const activeWorkspace = workspaces.find((_workspace => _workspace.ref.isEqual(workspace.ref)));

            await this.setActiveWorkspace(activeWorkspace);
            this.router.navigate(['/teams/settings']);
        }

        this.processing = false;
    }

    navigateToHome() {
        this.router.navigate([]);
    }

    getWorkspaceLogo(workspace: Workspace): SafeStyle {
        const url = workspace && workspace.imageUrl ? workspace.imageUrl : '../../../assets/images/img-placeholder.png';
        return this.sanitizer.bypassSecurityTrustStyle('url(' + url + ')');
    }

    toggleSidebar(){
        this.sidebarService.toggleSidebar();
    }

    public async openPlansDialog() {
        await this.router.navigate(['organization', 'create']);
    }
}
