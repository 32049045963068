import { Component, OnDestroy } from '@angular/core';
import { WorkspaceService } from '../../core/workspace/workspace.service';
import { Subscription } from 'rxjs';
import { ManageTeamAnalyticsService } from '../manage-teams/manage-team-analytics-service';
import { Workspace } from '../../core/models/workspace.model';
import { PRIVILEGES } from '../../core/privilege/privilege-roles';
import { PrivilegeService } from '../../core/privilege/privilege.service';
import { Router } from '@angular/router';
import { Organization } from 'src/app/core/models/organization.model';
import { FirestoreModel } from 'src/app/core/firebase/firestore-model';
import { OrganizationService } from 'src/app/organization/organization.service';
import { first } from 'rxjs/operators';
import { ToastController } from '@ionic/angular';
import { WorkspaceMember } from 'src/app/core/models/workspace-member';

@Component({
  selector: 'app-manage-teams',
  templateUrl: './manage-teams-page.component.html',
  styleUrls: ['./manage-teams-page.component.scss']
})
export class ManageTeamsPageComponent implements OnDestroy {

  workspace: Workspace;
  displayName: string;
  titleIsEditing = false;
  logoIconVisible: boolean;
  workspaceEditPrivilege = PRIVILEGES.WORKSPACE_EDIT;
  myMember: WorkspaceMember;

  organization: Organization;
  organizations: Organization[];

  private workspaceActivatedSubscription: Subscription;
  private progress = 0;

  constructor(public workspaceService: WorkspaceService,
    public analytics: ManageTeamAnalyticsService,
    public privilegeService: PrivilegeService,
    public organizationService: OrganizationService,
    private router: Router,
    public toastCtrl: ToastController,
  ) {

  }

  async ngOnInit() {
    this.workspaceActivatedSubscription = this.workspaceService.activatedWorkspace.subscribe(async (workspace) => {
      if(workspace.id==="demo-games"){
        await this.router.navigate(['/design']);
        return;
      }
      this.workspace = workspace;
      await this.checkPrivilege(workspace);
      this.organization = await workspace.ownerOrganization$.pipe(first()).toPromise();
    });
  }

  async checkPrivilege(workspace: Workspace) {
    this.myMember = await this.workspace.getMyMember();
    if(!this.myMember.active) { await this.router.navigateByUrl('/play-landing'); }
    switch (this.myMember.level) {
      case 'owner':
        break;
      case 'admin':
        break;
      case 'editor':
        break;
      case 'player':
        await this.router.navigateByUrl('/play-landing');
        break;
      default:
        break;
    }
  }

  ngOnDestroy(): void {
    this.workspaceActivatedSubscription.unsubscribe();
  }

  setTitle(): void {
    this.analytics.createTitleSaveButtonClickedEvent();
    this.workspace.save();
    this.titleIsEditing = false;
  }

  setUploadProgress(event): void {
    this.progress = event;
    if (this.progress === 0) {
      this.analytics.createLogoFileSelectedClickedEvent();
    }
    this.toggleIconVisibilityOnUpload();
  }

  initImageUpload($event: any) {
    this.workspace._image.setSizedUrl(URL.createObjectURL($event.target.files[0]));
  }

  private toggleIconVisibilityOnUpload() {
    if (this.isUploading() && this.logoIconVisible) {
      this.setLogoUploadIconVisibility(false);
    }
  }

  setLogoUploadIconVisibility(visible: boolean) {
    if (this.isUploading() && visible === true) {
      return;
    }
    this.logoIconVisible = visible;
  }

  async setLogo(url: string) {
    this.workspace.imageUrl = url;
    await this.workspace.save();
    this.analytics.createLogoUploadedEvent();
  }

  isUploading(): boolean {
    return this.progress > 0 && this.progress < 100;
  }

  removeWorkspace(): void {
    this.analytics.createLogoRemoveClickedEvent();
  }

  getWorkspaceLogo(): string {
    return this.workspace && this.workspace.imageUrl ? this.workspace.imageUrl : '/assets/images/img-placeholder.png';
  }

  public compareModel(a: FirestoreModel<any>, b: FirestoreModel<any>) {
    return a && b && a.ref.isEqual(b.ref);
  }

  async onOrganizationChange(selected: Organization) {
      if(selected.isOrphan()) {
        this.workspace.ownerOrganizationRef = null;
      } else {
        this.workspace.ownerOrganizationRef = selected.ref;
      }

      this.organization = selected;

      await this.workspace.save();
      await this.organizationService.setByWorkspaceRef(this.workspace.ref);
      const toast = await this.toastCtrl.create({
        color: 'primary text-center',
        message: 'Successfully saved',
        duration: 3000,
        position: 'top'
      });
      await toast.present();
  }

  async saveOrganization() {
    await this.onOrganizationChange(this.organization);
  }
}
