import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../auth/auth.guard';
import { BrowsePage } from './browse/browse.page';
import { PlayPage } from './play/play.page';
import { WorkspaceResolver } from 'src/app/core/models/resolvers/workspace.resolver';
import { PlayResolver } from '../core/models/resolvers/play.resolver';
import { ScriptResolver } from '../script/script.resolver';
import { PlayLandingComponent } from './landing/play-landing.component';
import { PlayPageModule } from '../event/play.module';
import { BrowsePageModule } from './browse/browse.module';
import { FeedComponent } from './play/feed/feed.component';

const routes: Routes = [
  {
    path: 'play-landing',
    component: PlayLandingComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'playing/game/:id',
    redirectTo: 'playing/browse/design-details/:id'
  },
  {
    path: 'playing/event/:playID',
    redirectTo: 'playing/play/:playID'
  },
  {
    path: 'playing/browse',
    redirectTo: 'playing/browse/play-list',
    pathMatch: 'full',
  },
  {
    path: 'playing/browse/play-list',
    loadChildren: () => import('./browse/play-list/play-list.module').then(m => m.PlayListPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'playing/browse/design-list-overview',
    loadChildren: () => import('./browse/script-list-overview/script-list-overview.module').then(m => m.ScriptListOverviewPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'playing/browse/design-list/:workspaceID',
    loadChildren: () => import('./browse/script-list/script-list.module').then(m => m.ScriptListPageModule),
    canActivate: [AuthGuard],
    resolve: { workspace: WorkspaceResolver }
  },
  {
    path: 'playing/browse/design-details/:scriptID',
    component: PlayPage,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./browse/script-details/script-details.module').then(m => m.ScriptDetailsPageModule),
        resolve: { script: ScriptResolver },
        pathMatch: 'full'
      },
    ]
  },
  {
    path: 'playing/play/:playID',
    component: PlayPage,
    canActivate: [AuthGuard],
    resolve: { play: PlayResolver },
    children: [
      {
        path: 'details',
        loadChildren: () => import('./play/play-details/play-details.module').then(m => m.PlayDetailsPageModule),
      },
      {
        path: 'task',
        children: [
          {
            path: 'list',
            component: FeedComponent
          },
          {
            path: 'view',
            loadChildren: () => import('./play/task-view/task-view.page.module').then(m => m.TaskViewPageModule)
          },
          {
            path: 'view/:taskID',
            loadChildren: () => import('./play/task-view/task-view.page.module').then(m => m.TaskViewPageModule)
          },
          {
            path: '',
            redirectTo: 'list',
            pathMatch: 'full'
          }
        ],
      },

      {
        path: '',
        redirectTo: 'details',
        pathMatch: 'full'
      }
    ]
  },
  {
    path: 'playing/profile',
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfilePageModule)
  },
  {
    path: 'teaser-modal',
    loadChildren: () => import('./browse/script-details/teaser-modal/teaser-modal-routing.module').then(m => m.TeaserModalPageRoutingModule)
  },

];

@NgModule({
  imports: [
    BrowsePageModule,
    PlayPageModule,
    RouterModule.forChild(routes)
  ],
  providers: [

  ],
  entryComponents: [
    BrowsePage,
    PlayPage
  ]
})
export class PlayingRoutingModule { }
