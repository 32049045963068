<div>
    <div *ngIf="activeAlternate" class="alternateContainer pb-3">
        <div class="info text-center d-block">
            <ion-button color="warning" (click)="setActiveAlternate(false)" fill="clear">
                <mat-icon slot="start">videocam</mat-icon> {{'media-capture/video/alternate/back-to-video/label' | translate}}
            </ion-button>
        </div>
        <task-input-text (onTextTyped)="onTextTyped.emit($event)" class="no-border px-2 d-block" *ngIf="activeAlternate === 'text'"></task-input-text>
    </div>
    <div *ngIf="!activeAlternate" class="cameraContainer {{status}}" [class.has-info]="hasInfo" [class.has-alternate]="alternateOptions?.length > 0">
        <div *ngIf="hasInfo" class="info visible-inactive">
            <div class="row mx-0 align-items-center">
                <div class="col-1 px-0 text-center">
                    <mat-icon class="mx-auto">info</mat-icon>
                </div>
                <div class="col pl-0">
                    <p class="pre rtl-text">
                        {{layoutItem?.getInstructionText()}}
                    </p>
                </div>
            </div>
        </div>
        <div class="start-camera-container">
            <div class="startCamera visible-inactive">
                <ion-button color="warning" size="small" (click)="getStream()" shape="round">
                    <mat-icon slot="start">videocam</mat-icon>
                    {{'media-capture/video/capture-button/label' | translate}}
                </ion-button>
                <ng-container *ngIf="alternateOptions?.length > 0">
                    <div class="clearfix"></div>
                    <p class="or">{{'general/or' | translate}}</p>
                    <ion-button *ngIf="!showAlternates" (click)="onShowAlternates()" color="secondary" fill="clear" size="small">
                    <mat-icon slot="icon-only">more_horiz</mat-icon>
                    </ion-button>
                    <ion-button *ngIf="showAlternates" (click)="setActiveAlternate('text')" class="mt-3" color="secondary" shape="round" size="small">
                        <mat-icon>subject</mat-icon> {{'media-capture/video/alternate/text/label' | translate}}
                    </ion-button>
                </ng-container>
            </div>
        <button class="shoot visible-active" (click)="record()"></button>
        <button class="shoot stop visible-recording" (click)="stop()"></button>
        <ion-button color="secondary" size="small" shape="round" class="visible-success" (click)="cancel()">
            <mat-icon slot="start">videocam</mat-icon> {{'media-capture/video/retake-button/label' | translate}}
        </ion-button>
        <ion-button *ngIf="this.status !== 'recording'" class="close visible-active invisible-inactive" color="secondary" fill="clear" (click)="backToStart()"><ion-icon name="close"></ion-icon></ion-button>        
        <video #captureVideo class="visible-inactive invisible-inactive visible-active visible-recording"></video>
        <video #previewVideo controls class="visible-success"></video>
        <div *ngIf="timer | async; let time" class="timer">{{time}}</div>
        </div>
    </div>
</div>
