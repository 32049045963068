<app-new-team-sidebar [navigateOnChange]="['/design']"></app-new-team-sidebar>

<ion-content [dir]="version?.direction">
  <app-profile-menu></app-profile-menu>
  <content-container *ngIf="readonly !== undefined" back-button="/design">
    <form>
      <div *ngIf="version" class="container">
        <div class="row intro">
          <div class="thumbnail-container mr-sm-4" (mouseover)="setThumbnailUploadIconVisibility(true)"
            (mouseleave)="setThumbnailUploadIconVisibility(false)" [ngClass]="thumbnailClasses()">
            <div class="thumbnail-upload-button-wrapper" [hidden]="readonly || !thumbnailIconVisible">
              <mat-icon slot="start" (click)="thumbnailUpload.click();  analytics.createScriptImageUploadClickedEvent()"
                class="thumbnail-upload-button">camera_alt</mat-icon>
              <input type="file" [fileInput]="version" [category]="'image'" (progress)="setProgress($event)"
                (change)="setProgress(0); initImageUpload($event);"
                (uploaded)="setThumbnail($event)"
                accept="image/jpeg,image/x-png"
                [fileMetadata]="{
                  fsDocPath: version.ref.path,
                  fsDocField: 'image.sizes',
                  maxSizes: (['xs', 'sm', 'md', 'lg', 'xlg'] | json),
                  isResize: 'true'
                }"
                class="thumbnail-upload-input" id="thumbnailUpload" #thumbnailUpload>
            </div>
            <div class="progress thumbnail-upload-progress-wrapper" [hidden]="readonly || !isUploading()">
              <ion-spinner name="lines"></ion-spinner>
              <span class="uploading-indicator-text">{{ 'script-details/action/edit/uploading' | translate}}</span>
            </div>
            <img *ngIf="version.imageUrl" [src]="version._image | size : 'md' : 'md' | safe : 'url'" [alt]="version.title" [hidden]="isUploading()"
              class="img-fluid thumbnail-image">
          </div>

          <div class="col-6 title">
            <div class="title-container" *ngIf="!titleIsEditing">
              <h1 [title]="version.title" class="rtl-text">
                {{version?.title}}
                <mat-icon id="btn-edit-title" (click)="onTitleEdit()" *ngIf="!readonly" class="edit-icon" slot="start">create</mat-icon>
              </h1>
            </div>

            <div class="title-container" *ngIf="titleIsEditing && scriptForm">
              <div class="title-editor">
                <div class="input-container">
                  <input type="text" id="scriptTitleName" name="scriptTitleName"
                    (focusin)="analytics.createTitleFieldClickedEvent()" [formControl]="getTitleFormControl()"
                    (keydown.enter)="saveTitle()" required>
                  <div *ngIf="getTitleFormControl().touched && getTitleFormControl().invalid"
                    class="alert invalid-field">
                    {{'task-edit/form/global/required' | translate}}
                  </div>
                </div>

                <div class="title-action">
                  <ion-button shape="round" type="submit" color="secondary" (click)="saveTitle()"
                    [disabled]="getTitleFormControl().invalid">
                    {{'general/ok' | translate}}
                  </ion-button>
                </div>
              </div>
            </div>

            <div class="info-bar" [class.published]="script.isPublished"
              [class.sync]="!(script.isPublicDiffers$ | async)" [class.unsync]="script.isPublicDiffers$ | async">
              <ng-container *ngIf="!script.isPublished">
                <div class="date float-left mr-4">
                  {{ 'script-card/unpublished/date' | translate }} {{script.updatedAt.toDate() | dateShort}}
                </div>
                <div class="status">
                  {{ 'script-card/unpublished/status' | translate | uppercase }}
                </div>
              </ng-container>
              <ng-container *ngIf="script.isPublished">
                <div class="date float-left mr-4">
                  <ng-container *ngIf="!(script.isPublicDiffers$ | async)">
                    {{ 'script-card/published/sync/date' | translate }} {{script?.public?.publishedAt?.toDate() | dateShort}}
                  </ng-container>
                  <ng-container *ngIf="script.isPublicDiffers$ | async">
                    {{ 'script-card/published/unsync/date' | translate }} {{script.updatedAt.toDate() | dateShort}}
                  </ng-container>
                </div>
                <div *ngIf="!readonly" class="status d-flex align-items-center">
                  <mat-icon>flash_on</mat-icon>
                  {{ 'script-card/published/status' | translate | uppercase }}
                </div>
              </ng-container>
            </div>
            <div class="clearfix"></div>
            <div class="actions">
              <ion-button *ngIf="readonly" [disabled]="isUploading()" (click)="presentUseTemplate()" class="mt-3 mr-2"
              color="primary" shape="round">
             <mat-icon slot="start">copy_all</mat-icon>
             {{'script-details/action/use-template/title' | translate}}
             </ion-button>

              <ion-button *ngIf="readonly" [disabled]="isUploading()" class="mt-3 mr-2"
                [routerLink]="['/design', script?.id, 'task-view']" color="secondary" shape="round">
                <mat-icon slot="start">remove_red_eye</mat-icon>
                {{'script-details/action/view/title' | translate}}
              </ion-button>

              <ion-button id="btn-edit-design" *ngIf="!readonly" [disabled]="isUploading()" class="mt-3 mr-2"
                [routerLink]="['/design', script?.id, 'task']" color="warning" shape="round">
                <mat-icon slot="start">create</mat-icon>
                {{'script-details/action/edit/title' | translate}}
              </ion-button>

              <ion-button id="btn-play-game" *ngIf="!readonly" (click)="openPlayModal()" [disabled]="isUploading()" class="mt-3 mr-2 pl-2"
                color="primary" shape="round">
                <mat-icon slot="start">play_arrow</mat-icon>
                {{'script-details/action/play' | translate}}
              </ion-button>
            </div>
          </div>
          <div *ngIf="!readonly" class="col offset-1 offset-lg-2 pl-2 context-menu-button">
            <script-context-menu [script]="script" class="float-right"></script-context-menu>
          </div>
        </div>
        <div class="row divider">
        </div>
        <div class="row description">
          <h2>{{'script-details/description/title' | translate}}</h2>
        </div>
        <div class="row">
          <div class="description-container" *ngIf="!descriptionIsEditing">
            <span class="script-description rtl-text pre" [title]="version.description ">
              {{version?.description}}
              <mat-icon id="btn-edit-desc" (click)="descriptionIsEditing = true; this.analytics.createDescriptionEditClickedEvent()"
                *ngIf="!readonly" class="edit-icon">create</mat-icon>
            </span>
          </div>

          <div class="description-container col-11 px-0" *ngIf="descriptionIsEditing && scriptForm">
            <div class="description-editor">
              <div class="input-container">
                <textarea type="text" (focusin)="this.analytics.createDescriptionFieldClickedEvent()"
                  id="scriptDescription" [formControl]="getDescriptionFormControl()" name="scriptDescription" rows="4"
                  required>
                <div *ngIf="getDescriptionFormControl().touched && getDescriptionFormControl().invalid"
                     class="alert invalid-field">
                  {{'task-edit/form/global/required' | translate}}
                </div>
                </textarea>
              </div>

              <div class="description-action">
                <ion-button shape="round" type="submit" color="secondary" (click)="saveDescription()"
                  [disabled]="getDescriptionFormControl().invalid">
                  {{'general/ok' | translate}}
                </ion-button>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div *ngIf="!readonly || (readonly && teaserURL)" class="col-6 video pr-sm-5">
            <div class="row">
              <div class="col-md-7 video-title-container">
                <h2>{{'script-details/video/title' | translate}}</h2>
              </div>
              <div class="col-md-5 url-editor-container" *ngIf="!readonly">
                <ion-button id="btn-edit-teaser-url" (click)="openUrlEditorModal()" class="mr-2" color="secondary" shape="round">
                  {{'script-details/video/edit-button' | translate}}
                </ion-button>
              </div>
            </div>
            <div class="row embed-responsive embed-responsive-16by9 rounded" *ngIf="version.hasTeaser()">
              <iframe class="embed-responsive-item rounded" [src]="teaserURL" allowfullscreen></iframe>
            </div>
          </div>
          <div class="col-6 roles" [class.pl-0]="(readonly && !teaserURL)">
            <h2>{{'script-details/roles/title' | translate}}</h2>
            <ul>
              <li *ngFor="let role of version.roles | facilitatorOrder">
                <span class="circle mr-2" [style.backgroundColor]="role.color"></span>{{role.title}}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </form>
    <form *ngIf="!readonly" #advanced="ngForm">
      <div class="row advanced">
        <div class="col-sm-6">
          <ion-button *ngIf="!advancedVisible" (click)="showAdvanced()" fill="clear" class="px-0">
            {{'script-details/advanced-settings/show-button/label' | translate}}</ion-button>
          <div *ngIf="advancedVisible">
            <div class="row">
              <div class="col-sm-6 col-md-6">
                <h2>{{'script-details/advanced-settings/game-content/title' | translate}}</h2>
                <div class="form-group">
                  <label
                    for="script-details/advanced-settings/game-content">{{'script-details/advanced-settings/game-content/option/label' | translate}}</label>
                  <select (change)="saveDirection($event)" id="script-details/advanced-settings/game-content" class="form-control">
                    <option value="ltr">
                      {{'script-details/advanced-settings/game-content/option/left-to-right' | translate}}</option>
                    <option value="rtl" [selected]="version?.direction === 'rtl'">
                      {{'script-details/advanced-settings/game-content/option/right-to-left' | translate}}</option>
                  </select>
                </div>
              </div>
            </div>
            <div *ngIf="(organizationService.activeOrganization$ | async)?.integrations?.lti || moodleConfig.validWorkspaces.includes(script.workspaceRef.id)" class="moodle">
              <h2>{{'script-details/advanced-settings/moodle/title' | translate}}</h2>
              <div class="form-group mb-0">
                <label>{{'script-details/advanced-settings/moodle/courseID/label' | translate}}</label>
                <inline-editor (ok)="saveMoodle('moodleCourseID', moodleCourseID)">
                  <input name="moodleCourseID" type="number" [(ngModel)]="moodleCourseID"
                    #moodleCourseIDModel="ngModel" />
                </inline-editor>
              </div>
              <div class="form-group mb-0">
                <label>{{'script-details/advanced-settings/moodle/courseModuleID/label' | translate}}</label>
                <inline-editor (ok)="saveMoodle('moodleCourseModuleID', moodleCourseModuleID)">
                  <input name="moodleCourseModuleID" type="number" [(ngModel)]="moodleCourseModuleID"
                    #moodleCourseModuleIDModel="ngModel" />
                </inline-editor>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>

  </content-container>
</ion-content>