<app-new-team-sidebar active="superadmin"></app-new-team-sidebar>
<ion-content>
  <app-profile-menu></app-profile-menu>
  <content-container class="py-5 px-3" [back-button]="'/superadmin/dashboard'" pageTitle="Plans">
    <ion-button  (click)="create()" class="top-right" color="primary" shape="round">Create new Plan</ion-button>
    <div class="row head mt-5 text-uppercase">
      <div class="col-3">Plan</div>
      <div class="col-4">Features</div>
      <div class="col-3">Pricing</div>
      <div class="col-2"></div>
    </div>
    <div *ngFor="let plan of plans" class="row my-3 mb-5">
      <div class="col-3">{{plan.title}}</div>
      <div class="col-4">
        Workspace limit: {{plan.features.workspaceLimit}}<br />
        Game / Workspace limit: {{plan.features.scriptPerWorkspaceLimit}}<br />
        Member / Workspace limit: {{plan.features.designerLimitPerWorkspace}}<br />
        Player limit: {{plan.features.playerLimit}}
      </div>
      <div class="col-3">
        <ng-container *ngIf="plan?.pricing?.monthly">
          <ng-container *ngFor="let currencyItem of plan.pricing.monthly.currencies | keyvalue">
            <ng-container *ngIf="currencyItem.key; let currency">
              <ng-container *ngIf="currencyItem.value; let currencyValue">
                Monthly: {{plan.pricing.monthly.currencies[currency].amount | currency: (currency | uppercase)}}
              </ng-container>
            </ng-container>
          </ng-container>
          <br />
        </ng-container>

        <ng-container *ngIf="plan?.pricing?.yearly">
          <ng-container *ngFor="let currencyItem of plan.pricing.yearly.currencies | keyvalue">
            <ng-container *ngIf="currencyItem.key; let currency">
              <ng-container *ngIf="currencyItem.value; let currencyValue">
                Yearly: {{plan.pricing.yearly.currencies[currency].amount | currency: (currency | uppercase)}}
              </ng-container>
            </ng-container>
          </ng-container>
          <br />
        </ng-container>
      </div>
      <div class="col-2 text-center">
        <context-menu-icon>      
          <context-item label="Edit" (click)="viewPlan(plan)"></context-item>
        </context-menu-icon>
      </div>
    </div>
  </content-container>
</ion-content>