<app-new-team-sidebar active="organize"></app-new-team-sidebar>
<ion-content>
  <app-profile-menu></app-profile-menu>
  <content-container *ngIf="play && scriptVersion">
    <h1>{{'play-edit/page-title' | translate}}</h1>
    <form #playEditForm="ngForm" (ngSubmit)="onSubmit()">
      <div class="row">
        <div class="col-sm-6">
          <h2>{{'play-edit/form/details/title' | translate}}</h2>
          <!-- TITLE -->
          <div class="script-thumbnail" [ngStyle]="{backgroundImage: 'url('+ scriptVersion.imageUrl +')'}"></div>
          <div class="form-group">
            <label for="play-title" class="label-with-popper">
              <span>{{'play-edit/form/details/title/label' | translate}}</span>
              <ion-icon name="help-circle" class="ml-1" [popper]="titleInfoTextPopper" [popperTrigger]="'hover'"
                [popperPlacement]="'right'" [popperApplyClass]="'info-text-popper'"></ion-icon>
              <popper-content popperApplyClass="popover-color" #titleInfoTextPopper>
                {{ 'play-edit/form/title/info-text' | translate }}
              </popper-content>
            </label>
            <input id="play-title" #title="ngModel" name="title" [(ngModel)]="play.title" class="w-100" required />
            <div *ngIf="title.getError('required')" class="alert invalid-field">
              {{'form/global/required' | translate}}
            </div>
          </div>
          <div class="clearfix"></div>
          <!-- DESCRIPTION -->
          <div class="form-group">
            <label for="play-description" class="label-with-popper">
              <span>{{'play-edit/form/details/description/label' | translate}}</span>
              <ion-icon name="help-circle" class="ml-1" [popper]="descriptionInfoTextPopper" [popperTrigger]="'hover'"
                [popperPlacement]="'right'" [popperApplyClass]="'info-text-popper'"></ion-icon>
              <popper-content popperApplyClass="popover-color" #descriptionInfoTextPopper>
                {{ 'play-edit/form/description/info-text' | translate }}
              </popper-content>
            </label>
            <ion-textarea id="play-description" #description="ngModel" name="description" [(ngModel)]="play.description"
              [autoGrow]="true" class="w-100">
            </ion-textarea>
            <div *ngIf="description.getError('required')" class="alert invalid-field">
              {{'play-edit/form/global/required' | translate}}
            </div>
          </div>

          <!-- DUE DATE & TIME-->
          <div *ngIf="false" class="form-group">
            <label for="play-announced-start-time" class="label-with-popper">
              <span>{{'play-edit/form/details/announcement-time/label' | translate}}</span>
              <ion-icon name="help-circle" class="ml-1" [popper]="announcedStartTimeInfoTextPopper" [popperTrigger]="'hover'"
                [popperPlacement]="'right'" [popperApplyClass]="'info-text-popper'"></ion-icon>
              <popper-content popperApplyClass="popover-color" #announcedStartTimeInfoTextPopper>
                {{ 'play-edit/form/announced-start-time/info-text' | translate }}
              </popper-content>
            </label>
          </div>

          <!-- LOCATION -->
          <div class="form-group">
            <label for="play-location" class="label-with-popper">
              <span>{{'play-edit/form/details/location/label' | translate}}</span>
              <ion-icon name="help-circle" class="ml-1" [popper]="locationInfoTextPopper" [popperTrigger]="'hover'"
                [popperPlacement]="'right'" [popperApplyClass]="'info-text-popper'"></ion-icon>
              <popper-content popperApplyClass="popover-color" #locationInfoTextPopper>
                {{ 'play-edit/form/location/info-text' | translate }}
              </popper-content>
            </label>
            <input id="play-location" #location="ngModel" name="location" [(ngModel)]="play.location" class="w-100" />
          </div>

          <!-- ORGANIZER NAME -->
          <div class="form-group">
            <label for="play-organizer-name" class="label-with-popper">
              <span>{{'play-edit/form/details/organizer-name/label' | translate}}</span>
              <ion-icon name="help-circle" class="ml-1" [popper]="organizerNameInfoTextPopper" [popperTrigger]="'hover'"
                [popperPlacement]="'right'" [popperApplyClass]="'info-text-popper'"></ion-icon>
              <popper-content popperApplyClass="popover-color" #organizerNameInfoTextPopper>
                {{ 'play-edit/form/organizer-name/info-text' | translate }}
              </popper-content>
            </label>
            <input id="play-organizer-name" #organizerName="ngModel" name="organizer-name"
              [(ngModel)]="play.organizerName" class="w-100" />
          </div>

          <!-- ORGANIZER EMAIL -->
          <div class="form-group">
            <label for="play-organizer-email" class="label-with-popper">
              <span>{{'play-edit/form/details/organizer-email/label' | translate}}</span>
              <ion-icon name="help-circle" class="ml-1" [popper]="organizerEmailInfoTextPopper" [popperTrigger]="'hover'"
                [popperPlacement]="'right'" [popperApplyClass]="'info-text-popper'"></ion-icon>
              <popper-content popperApplyClass="popover-color" #organizerEmailInfoTextPopper>
                {{ 'play-edit/form/organizer-email/info-text' | translate }}
              </popper-content>
            </label>
            <input id="play-organizer-email" #organizerEmail="ngModel" name="organizer-email"
              [(ngModel)]="play.organizerEmail" email class="w-100" />
          </div>
        </div>
        <div *ngIf="false" class="col-sm-6">
          <h2>{{'play-edit/form/settings/title' | translate}}</h2>
          <!--  PLAY START -->
          <div class="form-group w-md-50">
            <label for="play-sessionStart" class="label-with-popper">
              <span>{{'play-edit/form/settings/session-start/label' | translate}}</span>
              <ion-icon name="help-circle" [popper]="sessionStartInfoTextPopper" [popperTrigger]="'hover'"
                [popperPlacement]="'right'" [popperApplyClass]="'info-text-popper'"></ion-icon>
              <popper-content popperApplyClass="popover-color" #sessionStartInfoTextPopper>
                {{ 'play-edit/form/session-start/info-text' | translate }}
              </popper-content>
            </label>
            <select id="play-sessionStart" #startOn="ngModel" name="startOn" [(ngModel)]="play.startOn" class="form-control">
              <option value="players-ready">{{'play-edit/form/settings/session-start/players-ready/label' | translate}}
              </option>
              <option value="manually">{{'play-edit/form/settings/session-start/manually/label' | translate}}</option>
            </select>
          </div>
          <!-- ROLE SELECTION -->
          <div class="form-group w-md-50">
            <label for="play-roleSelection" class="label-with-popper">
              <span>{{'play-edit/form/settings/role-selection/label' | translate}}</span>
              <ion-icon name="help-circle" [popper]="roleSelectionInfoTextPopper" [popperTrigger]="'hover'"
                [popperPlacement]="'right'" [popperApplyClass]="'info-text-popper'"></ion-icon>
              <popper-content popperApplyClass="popover-color" #roleSelectionInfoTextPopper>
                {{ 'play-edit/form/role-selection/info-text' | translate }}
              </popper-content>
            </label>
            <select id="play-roleSelection" #startOn="ngModel" name="startOn" [(ngModel)]="play.playerCanSelectRole" class="form-control">
              <option [value]="true">{{'play-edit/form/settings/role-selection/can/label' | translate}}
              </option>
              <option [value]="false">{{'play-edit/form/settings/role-selection/can-not/label' | translate}}</option>
            </select>
          </div>
        </div>
        <div *ngIf="play.isNew" class="col-sm-12 actions form-group text-right mt-3">
          <ion-button (click)="cancel()" class="mr-3" color="secondary" shape="round">{{'general/cancel' | translate}}</ion-button>
          <ion-button (click)="save(false)" color="primary" shape="round" [disabled]="playEditForm.invalid || processing === 'save'">
            <ion-spinner *ngIf="processing === 'save'" name="lines-small"></ion-spinner>
            {{'general/save' | translate}}
          </ion-button>
          </div>
      </div>
      <h2 *ngIf="!play.isNew">{{'play-edit/form/members/title' | translate}}</h2>
      <div *ngIf="false && players" class="form-group invite">
        <label for="">{{'play-edit/form/members/invite/label' | translate}}</label>
        <div class="row">
          <div class="col-sm-9">
            <tag-input [(ngModel)]="newMembers" name="newMembers"
              [placeholder]="'play-edit/form/members/invite/placeholder' | translate"
              [secondaryPlaceholder]="'play-edit/form/members/invite/placeholder' | translate">
            </tag-input>
          </div>
          <div class="col-sm-3">
            <ion-button (click)="addMembers(newMembers)" color="secondary" shape="round">
              {{'play-edit/form/members/invite/add-button/label' | translate}}</ion-button>
          </div>
        </div>
      </div>
      <div *ngIf="!play.isNew" class="shareURL form-group mb-4">
        <div class="form-check mb-4">
          <input class="form-check-input" type="checkbox" (change)="toggleShare($event)" id="shareURLCheck" [checked]="play?.accessLevel !== 'private'">
          <label class="form-check-label" for="shareURLCheck">
            {{'play-edit/form/members/share/label' | translate}}
          </label>
          <ion-icon name="help-circle" class="ml-1" [popper]="organizerEmailInfoTextPopper" [popperTrigger]="'hover'"
          [popperPlacement]="'right'" [popperApplyClass]="'info-text-popper'"></ion-icon>
        <popper-content popperApplyClass="popover-color" #organizerEmailInfoTextPopper>
          {{ 'play-edit/form/enable-join/info-text' | translate }}
        </popper-content>
          <ion-button (click)="copyShareURL()" [disabled]="play?.accessLevel === 'private'" class="ml-4" color="secondary" shape="round">{{'play-edit/form/members/share/copy-url/title' | translate}}</ion-button>
          <ion-button (click)="copyShareCode()" [disabled]="play?.accessLevel === 'private'" class="ml-4" color="secondary" shape="round">{{'play-edit/form/members/share/copy-share-code/title' | translate:{shareCode: play?.share?.code | mask: mask} }}</ion-button>
        </div>
        <div class="clearfix"></div>
      </div>
      <div *ngIf="!play.isNew && players && teams && roles" class="row">
        <div *ngFor="let team of teams | teamOrder; let i = index" class="col-sm-6" [class.pr-0]="i%2==0">
          <play-team-box [team]="team" [players]="players" [roles]="roles" [teams]="teams" class="mb-3">
          </play-team-box>
        </div>
      </div>
        <div class="row">
          <div *ngFor="let team of play?.teams" class="form-group col-12 col-sm-6">
            <ul class="team">
              <label>{{team.title}}</label>
              <li *ngFor="let role of scriptVersion.roles | facilitatorOrder">
                <span class="circle" [style.backgroundColor]="role.color"></span>{{role.title}}
                <div class="members">

                </div>
              </li>
            </ul>
          </div>
        </div> -->
      <div *ngIf="false" class="text-center">
        <ion-button (click)="addTeam()" color="secondary" shape="round">
          <ion-icon name="add" slot="start"></ion-icon>
          {{'play-edit/form/teams/add-button/title' | translate}}
        </ion-button>
      </div>
      <div *ngIf="!play.isNew && teams" class="actions form-group text-right mt-3">
        <ion-button (click)="cancel()" class="mr-3" color="secondary" shape="round">{{'general/cancel' | translate}}</ion-button>
        <ion-button (click)="save()" color="primary" shape="round" [disabled]="playEditForm.invalid || processing === 'save'">
          <ion-spinner *ngIf="processing === 'save'" name="lines-small"></ion-spinner>
          {{'general/save-and-close' | translate}}
        </ion-button>
        <ion-button *ngIf="play.status === 'pre-start'" [disabled]="processing === 'start' || playEditForm.invalid || hasFreeSlot() || (teams?.length * roles?.length !== players?.length)" (click)="start()" color="warning" shape="round">
          <ion-spinner *ngIf="processing === 'start'" name="lines-small"></ion-spinner>
          {{'play-edit/form/start' | translate}}
        </ion-button>
      </div>
    </form>
  </content-container>
</ion-content>