import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SidebarService {
    private sidebarOpen = new BehaviorSubject<boolean>(true);
    public sidebarOpen$ = this.sidebarOpen.asObservable();

    toggleSidebar() {
        this.sidebarOpen.next(!this.sidebarOpen.value);
    }

    setSidebarState(state: boolean) {
        this.sidebarOpen.next(state);
    }

    getSidebarState() {
        return this.sidebarOpen.value;
    }
}
