import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-accordion-group',
  templateUrl: './accordion-group.component.html',
  styleUrls: ['./accordion-group.component.scss'],
})

export class AccordianGroupComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
