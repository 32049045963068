<div [ngClass]="{ 'nav-bar': true, 'closed': !isSideBarOpen }">
  <app-nav-header [isSideBarOpen]="isSideBarOpen" (toggleSidebarEvent)="toggleSidebar()"></app-nav-header>
  <div id="nav-content">
    <app-company-tile [style]="{'width': '100%'}" [isSideBarOpen]="isSideBarOpen"></app-company-tile>
    <div class="nav-accordian-group">
      <ng-container *ngIf="((organizationService.activeOrganization$ | async)?.myMember$ | async); let organizationMember">
        <div (click)="$event.stopPropagation(); openPlansDialog();" [class.disabled]="organizationMember?.level !== 'owner' && organizationMember?.level !== 'admin'" class="nav-add">
          <ion-spinner *ngIf="processing" name="lines-small"></ion-spinner>
          <mat-icon *ngIf="!processing">add</mat-icon>
          <span *ngIf="isSideBarOpen">Add team</span>
        </div>
      </ng-container>
      <app-accordion-group>
        <app-accordion-item
          *ngFor="let workspace of featuredWorkspaces"
          [isThisAccordionOpened]="(workspaceService.activatedWorkspace | async)?.ref.path === workspace.ref.path"
          [isSideBarOpen]="isSideBarOpen"
          [workspace]="workspace"
          [isFeatured]="true"
          [active]="active"
          (toggleAccordionEvent)="setActiveWorkspace(workspace)">
        </app-accordion-item>
        <hr *ngIf="featuredWorkspaces.length > 0"/>
        <app-accordion-item
          *ngFor="let workspace of workspaces"
          [isThisAccordionOpened]="(workspaceService.activatedWorkspace | async)?.ref.path === workspace.ref.path"
          [isSideBarOpen]="isSideBarOpen"
          [workspace]="workspace"
          [isFeatured]="false"
          [active]="active"
          (toggleAccordionEvent)="setActiveWorkspace(workspace)">
        </app-accordion-item>
      </app-accordion-group>
      <ng-container *ngIf="((organizationService.activeOrganization$ | async)?.myMember$ | async); let organizationMember">
        <div (click)="$event.stopPropagation(); addNewWorkspace()" [class.disabled]="organizationMember?.level !== 'owner' && organizationMember?.level !== 'admin'" class="nav-add">
          <ion-spinner *ngIf="processing" name="lines-small"></ion-spinner>
          <mat-icon *ngIf="!processing">add</mat-icon>
          <span *ngIf="isSideBarOpen">Add workspace</span>
        </div>
      </ng-container>
    </div>
  </div>
  <app-nav-footer [active]="active" [isSideBarOpen]="isSideBarOpen"></app-nav-footer>
</div>
