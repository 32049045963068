import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AngularFireModule } from '@angular/fire';

import { environment } from '../environments/environment';
import { AuthModule } from './auth/auth.module';
import { AnalyticsModule } from './core/analytics/analytics.module';
import { ScriptPageModule } from './script/script.module';
import { PlayPageModule } from './event/play.module';
import { DesignerModule } from './designer/designer.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PlayingModule } from './playing/playing.module';
import { UserProfileModule } from './user-profile/user-profile.module';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AppUpdateModule } from './core/app-update/app-update.module';
import { HeaderAnalyticsService } from './core/header/header.analytics';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { IonicStorageModule } from '@ionic/storage-angular';
import { SuperadminModule } from './superadmin/superadmin.module';
import { TeamsModule } from './teams/teams.module';
import { WorkspaceModule } from './core/workspace/workspace.module';
import { PrivilegeModule } from './core/privilege/privilege.module';
import { BrowserRecommendationModule } from './core/browser-recommendation/browser-recommendation.module';
import { OrganizationModule } from './organization/organization.module';
import { NgxStripeModule } from 'ngx-stripe';
import { EmailClientsModule } from './core/email-clients/email-clients.module';
import { SafePipeModule } from 'safe-pipe';
import { NgxPopperModule } from 'ngx-popper';
import { AngularFirePerformanceModule, PerformanceMonitoringService } from '@angular/fire/performance';
import { AngularFireRemoteConfigModule, SETTINGS } from '@angular/fire/remote-config';
import { PipesModule } from './core/pipes/pipes.module';
import { QuillModule } from 'ngx-quill'
import { CloudMessagingModule } from './core/cloud-messaging/cloud-messaging.module';
import { MomentModule } from 'ngx-moment';
import { ColorModule } from './core/color/color.module';
import { BrandingModule } from './core/branding/branding.module';
import { AiModule } from './ai/ai.module';
import { SimulatorModule } from './designer/simulator/simulator.module';
import { OrganizationNotFoundModule } from './organization/organization-not-found/organization-not-found.module';

// The translate loader needs to know where to load i18n files
// in Ionic's static asset pipeline.
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    IonicModule.forRoot({
    }),
    AppRoutingModule,
    PlayPageModule,
    ScriptPageModule,
    DesignerModule,
    HttpClientModule,
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: (createTranslateLoader),
            deps: [HttpClient]
        }
    }),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireMessagingModule,
    AngularFirestoreModule, 
    AngularFireFunctionsModule,
    AngularFirePerformanceModule,
    AngularFireRemoteConfigModule,
    AnalyticsModule.forRoot(),
    AuthModule.forRoot(),
    UserProfileModule,
    PlayingModule,
    AppUpdateModule,
    BrowserRecommendationModule,
    EmailClientsModule,
    SuperadminModule,
    AnalyticsModule.forRoot(),
    TeamsModule,
    PrivilegeModule,
    WorkspaceModule.forRoot(),
    IonicStorageModule.forRoot(),
    NgxStripeModule.forRoot(environment.payment.stripe.clientKey),
    OrganizationModule.forRoot(),
    SafePipeModule,
    NgxPopperModule.forRoot({}),
    PipesModule,
    QuillModule.forRoot(),
    CloudMessagingModule,
    MomentModule.forRoot(),
    BrandingModule.forRoot(),
    ColorModule,
    AiModule,
    SimulatorModule,
    OrganizationNotFoundModule
  ],
  providers: [
    PerformanceMonitoringService,
    StatusBar,
    SplashScreen,
    HeaderAnalyticsService,
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy
    },
    {
      provide: SETTINGS,
      useFactory: () => { return { minimumFetchIntervalMillis: 10000 } }
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
